import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import React from "react";
import { MdEmail } from "react-icons/md";
import { baseUrl } from "../services/services.profile";
import * as Yup from "yup";
import { useState } from "react";
import kybLogo from "../assets/images/login-header.png";

const ForgotPassword = ({ isOpen, setIsOpen, setModal }) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const forgotPassword = async (email) => {
    const res = await axios
      .post(baseUrl + "/api/auth/changePassword", {
        email,
      })
      .then((res) => {
        setSuccessMsg(res?.data?.message);
      })
      .catch((err) => {
        setErrorMsg(res?.data?.message);
      });
  };

  return (
    <>
      {isOpen ? (
        <>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Please enter valid email")
                .required("Please enter your email"),
            })}
            onSubmit={(values) => {
              let email = values.email;
              forgotPassword(email);
            }}
          >
            {({ handleSubmit, handleChange, handleBlur }) => (
              <div className="fixed inset-0 z-[999] overflow-y-auto ">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={() => setIsOpen(false)}
                ></div>
                <div className="flex flex-col justify-center items-center min-h-screen bg-[#00000073] py-8">
                  <div className="relative w-full max-w-[30rem] mx-auto bg-white rounded-md shadow-lg ">
                    <div className="flex justify-center items-center rounded-t-md  text-white font-bold p-6">
                      <img src={kybLogo} alt="" className=" w-[164px] pt-3" />
                    </div>
                    <div className="w-full flex items-center px-8 pt-4 pb-10 text-base ">
                      <form onSubmit={handleSubmit}>
                        <div>Reset your password</div>

                        <div
                          className={`${
                            successMsg
                              ? "text-[#155724] my-2 border-solid rounded border px-6 py-3 border-[#c3e6cb] bg-[#d4edda]"
                              : ""
                          }`}
                        >
                          {successMsg ? successMsg : errorMsg}
                        </div>
                        <div className="mb-4">
                          <div className="my-[10px] flex justify-center border-none outline-none items-center !mb-3 bg-[#00000013]">
                            <div className="bg-[#e9ecef] border border-solid border-[#ced4da] p-3">
                              <MdEmail />
                            </div>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Enter email"
                              className="border-none outline-none focus:outline-none "
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="email"
                            className="text-red-500 text-sm"
                          />
                        </div>

                        <button
                          type="submit"
                          className="text-lg border border-solid border-[#000] h-12 px-5 hover:text-white hover:bg-[#000]"
                        >
                          Reset Password
                        </button>
                      </form>
                    </div>
                    <hr />
                    <div className="flex justify-end items-center p-4">
                      <ul>
                        <li className="py-2">
                          <a
                            href="https://onboarding.kyb.pro/#/"
                            target="_blank"
                            className="hover:cursor-pointer hover:!text-[#181A1C]"
                          >
                            Join now
                          </a>
                        </li>
                        <li
                          className="hover:cursor-pointer py-2"
                          onClick={() => {
                            setModal(true);
                            setIsOpen(false);
                          }}
                        >
                          Sign In
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ForgotPassword;
