/* eslint-disable jsx-a11y/anchor-is-valid */
import { CheckOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import AccessRequest from "./AccessRequest";

const PricingPage = () => {
  const [accessRequest, setAccessRequest] = useState(false);

  return (
    <>
      {/* {AccessRequest && ( */}
      <AccessRequest
        accessRequest={accessRequest}
        setAccessRequest={setAccessRequest}
      />
      {/* )} */}
      <div className="login-page !bg-white">
        <div className="container mt-20 lgb:mt-[80px] mb-[70px]">
          <div className="text-center flex gap-5 flex-col !pt-[60px] justify-center items-center">
            <p className="text-[15px] !font-normal">PRICING</p>
            <h3 className="m-0 !font-bold !text-[48px]">
              Transparent, simple and adapted to your needs
            </h3>
            <div className="grid grid-cols-12 gap-6 mt-14 justify-center">
              <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
                <div className="mil-price-card !bg-transparent mb-[30px] h-full">
                  <h5 className="mb-[15px] mt-0">Starter</h5>
                  <p className="text-base mb-5 !text-[#7f7f7f]">
                    Best for smaller teams who want to simplify KYB/KYC
                    administration
                  </p>
                  <h3 className="mb-[30px]">
                    <div className="">
                      £ <span data-year-price={399}>399</span>
                      <span className="mil-sup-text !text-[#7f7f7f] !font-normal">
                        / year
                      </span>
                    </div>
                    <p className="text-sm !text-[#7f7f7f]">
                      £ 33 /equivalent per month
                    </p>
                  </h3>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setAccessRequest(true);
                    }}
                    className="mil-btn mil-m mil-fw mb-[30px]"
                  >
                    Request access
                  </a>

                  <div className="flex flex-col gap-2 !text-sm">
                    <div className="flex gap-2 items-center justify-center">
                      <CheckOutlined className="play-icon-gray" />
                      <div className="!text-[#7f7f7f] !font-semibold !">
                        25 Inspection reports per year
                      </div>
                    </div>
                    <div className="flex gap-2 items-center justify-center">
                      <CheckOutlined className="play-icon-gray" />
                      <p className="!text-sm !">
                        <span className="!text-[#7f7f7f]">Includes</span>
                        <span className="!text-[#7f8694] ml-1 !font-semibold">
                          Risk assessment reports
                        </span>
                      </p>
                    </div>

                    {[
                      "Customer management dashboard",
                      "3 team members",
                      "Cross-reference risk policy integration",
                      "Automated branded onboarding link",
                      "Customer onboarding support chat",
                      "24/7 ongoing customer monitoring",
                      "Email support",
                      "Free 30 minutes demo",
                    ].map((feature, index) => (
                      <div
                        key={index}
                        className="flex gap-2 items-center justify-center"
                      >
                        <CheckOutlined className="play-icon-gray" />
                        <p className="!text-sm !text-[#7f7f7f]">{feature}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="relative col-span-12 sm:col-span-6 lg:col-span-4 h-full">
                <div className="absolute -top-5 left-1/2 -translate-x-1/2 text-base px-5 py-2 bg-[#E7F21F] text-[#1a3732] flex items-center justify-center rounded-lg  font-semibold">
                  Most Popular
                </div>
                <div className="mil-price-card mil-featured mb-[30px] h-full">
                  <h5 className="mil-light mb-[15px] mt-0 opacity-95">Pro</h5>
                  <p className="text-base !text-white opacity-90 mb-5">
                    Ideal for professionals in law, real estate, accounting, and
                    art/luxury markets.
                  </p>
                  <h3 className="mil-light mb-[30px]">
                    <div className="!text-white">
                      £{" "}
                      <span
                        className="mil-light mil-pricing-table-price !text-white !opacity-100"
                        data-year-price="1,050"
                      >
                        1,050
                      </span>
                      <span className="mil-sup-text !text-white opacity-90 !font-normal">
                        / year
                      </span>
                    </div>
                    <p className="text-sm !text-white opacity-90">
                      £ 88 /equivalent per month
                    </p>
                  </h3>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setAccessRequest(true);
                    }}
                    className="cursor-pointer mb-[30px] h-14 text-base px-[30px] bg-[#E7F21F] text-[#1a3732] flex items-center justify-center rounded-lg  font-semibold hover:bg-[#e6ee59] transition duration-300"
                  >
                    Request access
                  </div>
                  <div className="flex flex-col gap-12">
                    <div className="flex gap-2 items-center justify-center">
                      <CheckOutlined className="play-icon-w" />
                      <div className="!text-sm !">
                        <span className="!text-white opacity-80">
                          All the features of
                        </span>
                        <span className="ml-1 !font-semibold !text-white !opacity-100">
                          Starter
                        </span>
                        <span className="!text-white opacity-80">, and:</span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 !text-sm">
                      <div className="flex gap-2 items-center justify-center">
                        <CheckOutlined className="play-icon-w" />
                        <div className="!text-white !font-semibold !">
                          75 Inspection reports per year
                        </div>
                      </div>
                      <div className="flex gap-2 items-center justify-center">
                        <CheckOutlined className="play-icon-w" />
                        <div className="!text-white opacity-80 ">
                          5 team members
                        </div>
                      </div>
                      <div className="flex gap-2 items-center justify-center">
                        <CheckOutlined className="play-icon-w" />
                        <div className="!text-white opacity-80 ">
                          Priority email support
                        </div>
                      </div>
                      <div className="flex gap-2 items-center justify-center">
                        <CheckOutlined className="play-icon-w" />
                        <div className="!text-white opacity-80 ">
                          API access
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
                <div className="mil-price-card !bg-transparent mb-[30px] h-full">
                  <h5 className=" mb-[15px] mt-0">Enterprise</h5>
                  <p className="text-base mb-[30px] !text-[#7f7f7f]">
                    Volume-based for banks, fintechs, neobanks, and crypto
                    firms.
                  </p>

                  <h4 className="mb-[30px] pb-3">
                    Contact sales
                    <p className="text-sm h-6" />
                  </h4>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setAccessRequest(true);
                    }}
                    className="mil-btn mil-m mil-fw mb-5"
                  >
                    Contact us
                  </a>
                  <div className="flex flex-col gap-12 !text-sm">
                    <div className="flex gap-2 items-center justify-center">
                      <CheckOutlined className="play-icon-gray" />

                      <p className=" !text-sm !">
                        <span className="!text-[#7f7f7f]">
                          All the features of
                        </span>
                        <span className="!text-[#7f8694] ml-1 !font-semibold">
                          Pro
                        </span>
                        <span className="!text-[#848994]">, and:</span>
                      </p>
                    </div>
                    <div className="flex flex-col gap-2">
                      {[
                        "Unlimited team members",
                        "Dedicated technical consultant",
                        "Enterprise Customer Success Manager",
                      ].map((feature, index) => (
                        <div
                          key={index}
                          className="flex gap-2 items-center justify-center"
                        >
                          <CheckOutlined className="play-icon-gray" />
                          <p className="!text-sm !text-[#7f7f7f]">{feature}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPage;
