/* eslint-disable no-whitespace-before-property */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */

//https://artpass.id | baseUrl
import { baseUrl, getAdminProfile } from "../services/services.profile";
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { message } from "antd";
import NotFoundData from "../components/NotFoundData";
import { IoMdClose } from "react-icons/io";
import { PDFDocument } from "pdf-lib";
import { useUserContext } from "../contexts/userContext";
import { useLocation } from "react-router";
import WhitelistedFilterDrawer from "../components/shared/Drawer/WhitelistedFilterDrawer";

import NetworkRiskReviewPendingBusinessDrawer from "../components/shared/Drawer/NetworkRiskReviewPendingDrawer/networkRiskReviewPendingBusinessDrawer";
import RiskReviewPendingBusinessDrawer from "../components/shared/Drawer/RiskReviewPendingDrawer/riskReviewPendingBusinessDrawer";
import RiskReviewPendingPatronDrawer from "../components/shared/Drawer/RiskReviewPendingDrawer/riskReviewPendingPatronDrawer";
import NetworkRiskReviewPendingPatronDrawer from "../components/shared/Drawer/NetworkRiskReviewPendingDrawer/networkRiskReviewPendingPatronDrawer";

const ConnectionsRequests = () => {
  const location = useLocation();
  const { user, RoleManage, userData } = useUserContext();
  const [removeAccess, setRemoveAccess] = useState([
    RoleManage?.MLRO,
    RoleManage?.ADMIN,
  ]);
  const { Card, Empty } = require("antd");
  const [profile, setProfile] = useState({});
  const [networks, setNetworks] = useState([]);
  const [connections, setConnections] = useState([]);
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isOpenNetwork, setIsOpenNetwork] = useState(false);
  const [data, setData] = useState({});
  const [connectionDetail, setConnectionDetail] = useState({});
  const [networkDetail, setNetworkDetail] = useState({});
  const [countryCode, setCountryCode] = useState("");
  const [connectCountryCode, setConnectCountryCode] = useState("");
  const [search, setSearch] = useState("");
  let [risk, setRisk] = useState([""]);
  let [userType, setUserType] = useState("");
  let [riskReviewPending, setRiskReviewPending] = useState([]);
  let [expiredDoc, setExpiredDoc] = useState([]);
  let [riskRating, setRiskRating] = useState([""]);
  const [filterList, setFilterList] = useState([]);
  let [fill, setFill] = useState("");
  let [riskArr, setRiskArr] = useState([]);
  let [optionVal, setOptionVal] = useState("");
  const [connectionCount, setConnectionCount] = useState(0);
  const [networkCount, setNetworkCount] = useState(0);
  const [isMerging, setIsMerging] = useState(false);
  const [docList, setDocList] = useState([]);
  const [accessPII, setAccessPII] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [filteredConnections, setFilteredConnections] = useState([]);
  const [filteredNetwork, setFilteredNetwork] = useState([]);
  const [callFilter, setCallFilter] = useState(false);
  const [showFilterList, setShowFilterList] = useState(false);
  // const [filteredDataCount, setFilterDataCount] = useState(0);
  const [networksLoaded, setNetworksLoaded] = useState(false);
  const [networksLoadedData, setNetworksLoadedData] = useState(false);
  const [connectionsLoaded, setConnectionsLoaded] = useState(false);
  const [connectionsLoadedData, setConnectionsLoadedData] = useState(false);
  const [locationkey, setLocationkey] = useState({ filter: "" });

  const [settingsFilterValueChange, setSettingsFilterValueChange] =
    useState(false);

  const [filterData, setFilterData] = useState({
    riskLevel: [],
    riskReviewPending: [],
    riskType: [],
    userType: [],
    expiredDoc: [],
    department: [],
    scope: [
      localStorage.getItem("scopeFilter", risk)
        ? localStorage.getItem("scopeFilter", risk)
        : "allConnections",
    ],
  });

  const combinedSelectedFilters = Object.values(filterData).flat();

  const combinedSelectedFiltersForFilterBtn = Object.entries(filterData)
    .filter(([key]) => key !== "scope")
    .map(([, value]) => value)
    .flat();

  // const combinedSelectedFilters = Object.entries(filterData)
  //   .map(([, value]) => value)
  //   .flat();

  const filterDrawerOpenClose = () => {
    handleClearFilter();
    setFilterDrawerOpen(false);
  };

  const checkExpiry = (date) => {
    const cddDate = new Date(date);
    const currentDate = new Date();

    if (currentDate > cddDate) {
      return true;
    } else {
      return false;
    }
  };

  const riskLevelFilter = (connectionData, applyedRiskFilter) => {
    var isMatchingRecord = false;
    applyedRiskFilter.forEach((item) => {
      if (item === connectionData.overrall_Risk) {
        isMatchingRecord = true;
      }
    });
    return isMatchingRecord;
  };

  const riskReviewPandingFilter = (connectionData) => {
    var isMatchingRecord =
      connectionData.comment_list.length === 0 &&
      connectionData.risk_status === "";
    return isMatchingRecord;
  };

  const riskTypefilter = (connectionData, riskTypes, risk) => {
    var isMatchingRecord = false;
    if (riskTypes.length > 0 && risk.length > 0) {
      const recordSet = new Set();

      connectionData.risk_rating_list.forEach((el) => {
        for (let i = 0; i < riskTypes.length; i++) {
          const item = riskTypes[i];
          if (item === el.name && el.color !== "") {
            for (let j = 0; j < risk.length; j++) {
              // Changed inner loop variable to j
              const element = risk[j];
              let color = "";
              if (element === "Low") {
                color = "bg-[#70ad47]";
              } else if (element === "Medium") {
                color = "bg-[#ffc000]";
              } else {
                color = "bg-[#ff0000]";
              }
              if (color === el.color) {
                isMatchingRecord = true;
              }
            }
          }
        }
      });
    } else if (riskTypes.length > 0) {
      connectionData.risk_rating_list.forEach((el) => {
        for (let i = 0; i < riskTypes.length; i++) {
          const item = riskTypes[i];
          if (item === el.name && el.color !== "") {
            isMatchingRecord = true;
          }
        }
      });
    }
    return isMatchingRecord;
  };

  const uTypeFilter = (connectionData, applyedUTypeFilter, type) => {
    var isMatchingRecord = false;

    let userTypeName;
    if (applyedUTypeFilter.includes("Natural Person")) {
      userTypeName = "PATRON";
    } else if (applyedUTypeFilter.includes("Legal Entity")) {
      userTypeName = "BUSINESS";
    } else {
      userTypeName = "";
    }

    if (type === "networks") {
      isMatchingRecord = connectionData.user_id.utype === userTypeName;
    } else {
      isMatchingRecord =
        (connectionData?.sender?._id === profile?._id &&
          connectionData?.receiver?.utype === userTypeName) ||
        (connectionData?.receiver?._id === profile?._id &&
          connectionData?.sender?.utype === userTypeName);
    }

    return isMatchingRecord;
  };

  const expiredFilter = (connectionData, applyedExpiredFilter, type) => {
    var isMatchingRecord = false;

    if (applyedExpiredFilter.length > 0) {
      if (applyedExpiredFilter.includes("Expired CDD's")) {
        if (type === "connections") {
          const cddExpired = checkExpiry(
            profile?._id === connectionData?.sender?._id
              ? connectionData?.receiver?.cdd?.validTill
              : connectionData?.sender?.cdd?.validTill
          );
          if (cddExpired) {
            isMatchingRecord = true;
          }
        } else {
          const cddExpired = checkExpiry(
            connectionData?.user_id?.cdd?.validTill
          );
          if (cddExpired) {
            isMatchingRecord = true;
          }
        }
      }

      if (applyedExpiredFilter.includes("Expired ID's")) {
        if (type === "connections") {
          const idExpired = checkExpiry(
            profile?._id === connectionData?.sender?._id
              ? moment(
                  connectionData?.receiver?.amlReport?.appData?.info?.idDocs
                    ?.slice()
                    ?.reverse()
                    ?.find((a) => a.idDocType && a.validUntil)?.validUntil
                ).format("DD MMM YYYY")
              : moment(
                  connectionData?.sender?.amlReport?.appData?.info?.idDocs
                    ?.slice()
                    ?.reverse()
                    ?.find((a) => a.idDocType && a.validUntil)?.validUntil
                ).format("DD MMM YYYY")
          );
          if (idExpired) {
            isMatchingRecord = true;
          }
        } else {
          const idExpired = checkExpiry(
            moment(
              connectionData?.user_id?.amlReport?.appData?.info?.idDocs
                ?.slice()
                ?.reverse()
                ?.find((a) => a.idDocType && a.validUntil)?.validUntil
            ).format("DD MMM YYYY")
          );
          if (idExpired) {
            isMatchingRecord = true;
          }
        }
      }

      if (applyedExpiredFilter.includes("On Hold")) {
        if (type === "connections") {
          isMatchingRecord =
            profile?._id === connectionData?.sender?._id
              ? connectionData?.receiver?.hold
              : connectionData?.sender?.hold;
        } else {
          isMatchingRecord = connectionData.hold ? true : false;
        }
      }
    }

    return isMatchingRecord;
  };

  const departmentsFilter = (connectionData, applyedDepartmentFilter) => {
    var isMatchingRecord = connectionData?.manageConnection?.department?.some(
      (departmentsId) =>
        applyedDepartmentFilter.some(
          (department) => department._id === departmentsId._id
        )
    );
    return isMatchingRecord;
  };

  const scopeFilter = (connectionData, applyedScopeFilter) => {
    const isMatchingRecord =
      applyedScopeFilter[0] === "inScope"
        ? connectionData?.manageConnection?.inScope === true
          ? true
          : !connectionData?.manageConnection
          ? true
          : false
        : applyedScopeFilter[0] === "outOfScope"
        ? connectionData?.manageConnection?.inScope === false
          ? true
          : !connectionData?.manageConnection && false
        : true;

    return isMatchingRecord;
  };

  const handleSave = (connectionsData, type, filterData) => {
    let filterRecord = [];

    for (let i = 0; i < connectionsData.length; i++) {
      const connectionData = connectionsData[i];
      var isMatchingRecord = true;

      if (isMatchingRecord && filterData["riskLevel"].length > 0) {
        isMatchingRecord = riskLevelFilter(
          connectionData,
          filterData["riskLevel"]
        );
      }

      if (isMatchingRecord && filterData["riskReviewPending"].length > 0) {
        isMatchingRecord = riskReviewPandingFilter(
          connectionData,
          filterData["riskReviewPending"]
        );
      }

      if (isMatchingRecord && filterData["riskType"].length > 0) {
        isMatchingRecord = riskTypefilter(
          connectionData,
          filterData["riskType"],
          filterData["riskLevel"]
        );
      }

      if (isMatchingRecord && filterData["userType"].length > 0) {
        isMatchingRecord = uTypeFilter(
          connectionData,
          filterData["userType"],
          type
        );
      }
      if (isMatchingRecord && filterData["expiredDoc"].length > 0) {
        isMatchingRecord = expiredFilter(
          connectionData,
          filterData["expiredDoc"],
          type
        );
      }

      if (isMatchingRecord && filterData["department"].length > 0) {
        isMatchingRecord = departmentsFilter(
          connectionData,
          filterData["department"],
          type
        );
      }

      if (isMatchingRecord && filterData["scope"].length > 0) {
        isMatchingRecord = scopeFilter(
          connectionData,
          filterData["scope"],
          type
        );
      }

      if (isMatchingRecord) {
        filterRecord.push(connectionData);
      }
    }

    return filterRecord;
  };

  useEffect(() => {
    const connectionFilteredDataList = handleSave(
      connections,
      "connections",
      filterData
    );
    const networkFilteredDataList = handleSave(
      networks,
      "networks",
      filterData
    );
    setCount(
      connectionFilteredDataList.length + networkFilteredDataList.length
    );
  }, [filterData]);

  useEffect(() => {
    const newFilterData = {
      riskLevel: [],
      riskReviewPending: [],
      riskType: [],
      userType: [],
      expiredDoc: [],
      department: [],
      scope: filterData.scope,
    };

    const connectionFilteredDataList = handleSave(
      connections,
      "connections",
      newFilterData
    );
    const networkFilteredDataList = handleSave(
      networks,
      "networks",
      newFilterData
    );
    if (settingsFilterValueChange) {
      setFilteredConnections(connectionFilteredDataList);
      setFilteredNetwork(networkFilteredDataList);
      setSettingsFilterValueChange(false);
    }
  }, [settingsFilterValueChange]);

  const mergeNetworkPDFs = async (networkDetail) => {
    let docArr = [];
    let CddPdf = "";
    let IRPdf = "";
    let UboPdf = "";
    let PolPdf = "";
    if (networkDetail?.user_id?.company) {
      CddPdf =
        networkDetail?.user_id?.cdd?.fileP &&
        baseUrl + "/" + networkDetail?.user_id?.cdd?.fileP;
      IRPdf =
        networkDetail?.user_id?.InspectRp?.fileUrl &&
        baseUrl + "/" + networkDetail?.user_id?.InspectRp?.fileUrl;
      UboPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company.cert &&
        baseUrl + "/" + networkDetail?.user_id?.company?.cert;
      PolPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company?.poa_le &&
        baseUrl + "/" + networkDetail?.user_id?.company?.poa_le;
    } else {
      CddPdf =
        networkDetail?.user_id?.cdd?.fileP &&
        baseUrl + "/" + networkDetail?.user_id?.cdd?.fileP;
      IRPdf =
        networkDetail?.user_id?.InspectRp?.fileUrl &&
        baseUrl + "/" + networkDetail?.user_id?.InspectRp?.fileUrl;
      UboPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company?.cert &&
        baseUrl + "/" + networkDetail?.user_id?.company?.cert;
    }

    if (docList?.length > 0) {
      for (let i = 0; i < docList.length; i++) {
        const el = docList[i];
        docArr.push(baseUrl + "/" + el?.filename);
      }
    }
    try {
      // Create a new PDF document to hold the merged content
      const mergedPdf = await PDFDocument.create();
      let pdfUrls = [];

      // Assuming this is the URL to the PDF
      if (networkDetail?.user_id?.company) {
        let arr = [];
        arr = [CddPdf, IRPdf, UboPdf, PolPdf];
        pdfUrls = arr.concat(docArr);
      } else {
        let arr = [];
        arr = [CddPdf, IRPdf];
        pdfUrls = arr.concat(docArr);
      }

      for (const pdfUrl of pdfUrls) {
        // Fetch the PDF data from the URL
        const response = await fetch(pdfUrl);
        const pdfData = new Uint8Array(await response.arrayBuffer());

        // Load the fetched PDF data into a PDF document
        const pdf = await PDFDocument.load(pdfData);

        // Copy pages from the fetched PDF to the merged PDF
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      // Serialize the merged PDF to a Uint8Array
      const mergedPdfBytes = await mergedPdf.save();

      // Create a Blob from the Uint8Array data
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      // Open the merged PDF in a new window/tab
      const newWindow = window.open(
        URL.createObjectURL(mergedPdfBlob),
        "_blank"
      );
      if (newWindow) {
        newWindow.focus();
      } else {
        console.error("Unable to open a new window for the PDF.");
      }
    } catch (error) {
      console.error("Error merging and displaying PDFs:", error);
    }
  };

  const holdUser = async (connector) => {
    console.log(connector._id);
    const res = await axios
      .post(baseUrl + "/api/connect/hold", {
        _id: connector._id,
        hold: true,
      })
      .then((res) => {
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
      })
      .catch((error) => {
        message.error(res?.data?.error);
      });
  };

  const unHoldUser = async (connector) => {
    if (localStorage.userId === "652943c69df2a7301b23c9ab") {
      const res = await axios
        .post(baseUrl + "/api/connect/hold", {
          _id: connector._id,
          hold: false,
        })
        .then((res) => {
          if (filterList.length > 0) {
            connectionFilter();
          } else {
            getConnections();
          }
        })
        .catch((error) => {
          message.error(res?.data?.error);
        });
    }
  };

  const unHoldUserNetwork = async (network) => {
    if (localStorage.userId === "652943c69df2a7301b23c9ab") {
      const res = await axios
        .post(baseUrl + "/api/connect/hold", {
          _id: network.user_id,
          hold: false,
        })
        .then((res) => {
          if (filterList.length > 0) {
            networkFilter();
          } else {
            getNetworks();
          }
        })
        .catch((error) => {
          message.error(res?.data?.error);
        });
    }
  };

  const activateUpdate = async (connection) => {
    console.log(connection);
    const res = await axios
      .post(baseUrl + "/api/connect/activate_update", {
        _id: connection.receiver._id,
      })
      .then((res) => {
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
      })
      .catch((error) => {
        message.error(res?.data?.error);
      });
  };

  const downloadUpdate = async (connection, connector) => {
    // handleMergeClick(connector, connection);
    const res = await axios
      .post(baseUrl + "/api/connect/downloaded_update", {
        conn_id: connection._id,
        user_id: connector._id,
      })
      .then((res) => {
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
      })
      .catch((error) => {
        message.error(res?.data?.error);
      });
  };

  const handleMergeClick = (user, connection) => {
    // if(user.InspectRp2 && user.InspectRp2.fileUrl) {
    // connector is as user
    downloadUpdate(connection, user);
    if (
      user.InspectRp2 &&
      user.InspectRp2.length > 0 &&
      Array.isArray(user.InspectRp2)
    ) {
      window.open(
        baseUrl + "/" + user.InspectRp2[user.InspectRp2.length - 1].fileUrl
      );
      // console.log("opened file-",user.InspectRp2[user.InspectRp2.length-1].fileUrl)
    } else {
      window.open(baseUrl + "/api/connect/irs/" + connection._id, "_blank");
    }
    // setIsMerging(true);
    // mergePDFs(user, connection);
    // setIsMerging(false);
  };

  const mergePDFs = async (userData, connection) => {
    let docArr = [];
    let CddPdf = "";
    let IRPdf = "";
    let UboPdf = "";
    let PolPdf = "";
    if (userData?.company) {
      CddPdf = userData?.cdd?.fileP && baseUrl + "/" + userData?.cdd?.fileP;
      IRPdf =
        userData?.InspectRp?.fileUrl &&
        baseUrl + "/" + userData?.InspectRp?.fileUrl;
      UboPdf =
        userData?.company &&
        userData?.company.cert &&
        baseUrl + "/" + userData?.company?.cert;
      PolPdf =
        userData?.company &&
        userData?.company?.poa_le &&
        baseUrl + "/" + userData?.company?.poa_le;
    } else {
      CddPdf = userData?.cdd?.fileP && baseUrl + "/" + userData?.cdd?.fileP;
      IRPdf =
        userData?.InspectRp?.fileUrl &&
        baseUrl + "/" + userData?.InspectRp?.fileUrl;
      UboPdf =
        userData?.company &&
        userData?.company?.cert &&
        baseUrl + "/" + userData?.company?.cert;
    }

    if (docList?.length > 0) {
      for (let i = 0; i < docList.length; i++) {
        const el = docList[i];
        docArr.push(baseUrl + "/" + el?.filename);
      }
    }

    try {
      // Create a new PDF document to hold the merged content
      const mergedPdf = await PDFDocument.create();
      let pdfUrls = [];

      // the URL to the PDF
      if (userData.company) {
        let arr = [];
        // arr = [CddPdf, IRPdf, UboPdf, PolPdf,];
        userData.company.ubo_LE.forEach((le) => {
          if (le.uname) {
            arr.push(baseUrl + "/api/connect/irs/" + connection._id);
          }
        });
        pdfUrls = arr.concat(docArr);
      } else {
        let arr = [];
        arr = [CddPdf, IRPdf];
        pdfUrls = arr.concat(docArr);
      }

      for (const pdfUrl of pdfUrls) {
        // Fetch the PDF data from the URL
        let response;
        if (pdfUrl.indexOf(".pdf") >= 0) {
          response = await fetch(pdfUrl);
        } else {
          response = await fetch(pdfUrl, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
        }
        const pdfData = new Uint8Array(await response.arrayBuffer());

        // Load the fetched PDF data into a PDF document
        const pdf = await PDFDocument.load(pdfData);

        // Copy pages from the fetched PDF to the merged PDF
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      // Serialize the merged PDF to a Uint8Array
      const mergedPdfBytes = await mergedPdf.save();

      // Create a Blob from the Uint8Array data
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      // Open the merged PDF in a new window/tab
      const newWindow = window.open(
        URL.createObjectURL(mergedPdfBlob),
        "_blank"
      );
      if (newWindow) {
        newWindow.focus();
      } else {
        console.error("Unable to open a new window for the PDF.");
      }
    } catch (error) {
      console.error("Error merging and displaying PDFs:", error);
    }
  };

  const buttonList = [
    {
      id: 1,
      name: "Low",
      border: "border-[#70ad47]",
      text: "text-[#70ad47]",
      bg: "",
    },
    {
      id: 2,
      name: "Medium",
      border: "border-[#ffc000]",
      text: "text-[#ffc000]",
      bg: "",
    },
    {
      id: 3,
      name: "High",
      border: "border-[#ff0000]",
      text: "text-[#ff0000]",
      bg: "",
    },
  ];

  const countryCodes = [
    { name: "Albania (+355)", code: "355", iso2code: "ALB" },
    { name: "Algeria (+213)", code: "213", iso2code: "DZA" },
    { name: "American Samoa (+684)", code: "684", iso2code: "ASM" },
    { name: "Andorra (+376)", code: "376", iso2code: "AND" },
    { name: "Angola (+244)", code: "244", iso2code: "AGO" },
    { name: "Argentina (+54)", code: "54" },
    { name: "Armenia (+374)", code: "374" },
    { name: "Aruba (+297)", code: "297" },
    { name: "Australia (+61)", code: "61", iso2code: "AUS" },
    { name: "Austria (+43)", code: "43", iso2code: "AUT" },
    { name: "Azerbaijan (+994)", code: "994" },
    { name: "Bangladesh (+880)", code: "+880" },
    { name: "Belarus (+375)", code: "375" },
    { name: "Belgium (+32)", code: "32", iso2code: "BEL" },
    { name: "Bolivia (+591)", code: "591" },
    { name: "Bosnia and Herzegovina (+387)", code: "387" },
    { name: "Botswana (+267)", code: "267" },
    { name: "Brazil (+55)", code: "55" },
    { name: "Bulgaria (+359)", code: "359" },
    { name: "Cambodia (+855)", code: "855" },
    { name: "Cameroon (+237)", code: "237" },
    { name: "Canada (+1)", code: "1", iso2code: "CAN" },
    { name: "Chile (+56)", code: "56" },
    { name: "China (+86)", code: "86" },
    { name: "Colombia (+57)", code: "57" },
    { name: "Costa Rica (+506)", code: "506" },
    { name: "Croatia (+38)", code: "38" },
    { name: "Cuba (+53)", code: "53" },
    { name: "Czech Republic (+420)", code: "420" },
    { name: "Denmark (+45)", code: "45", iso2code: "DNK" },
    { name: "Ecuador (+593)", code: "593" },
    { name: "Egypt (+20)", code: "20" },
    { name: "El Salvador (+503)", code: "503" },
    { name: "Estonia (+372)", code: "372" },
    { name: "Ethiopia (+251)", code: "251" },
    { name: "Finland (+358)", code: "358", iso2code: "FIN" },
    { name: "France (+33)", code: "33", iso2code: "FRA" },
    { name: "Germany (+49)", code: "49", iso2code: "DEU" },
    { name: "Greece (+30)", code: "30" },
    { name: "Hungary (+36)", code: "36" },
    { name: "Iceland (+354)", code: "354", iso2code: "ISL" },
    { name: "India (+91)", code: "91" },
    { name: "Indonesia (+62)", code: "62" },
    { name: "Italy (+39)", code: "39" },
    { name: "Japan (+81)", code: "81" },
    { name: "Mexico (+52)", code: "52" },
    { name: "Netherlands (+31)", code: "31", iso2code: "NLD" },
    { name: "New Zealand (+64)", code: "64", iso2code: "NZL" },
    { name: "Poland (+48)", code: "48" },
    { name: "Romania (+40)", code: "40" },
    { name: "Switzerland (+41)", code: "41" },
    { name: "United Kingdom (+44)", code: "44", iso2code: "GBR" },
    { name: "United States of America (+1)", code: "1", iso2code: "USA" },
    { name: "Venezuela (+58)", code: "58" },
    { name: "Vietnam (+84)", code: "84", iso2code: "VNM" },
  ];

  const getConnections = async () => {
    setConnectionsLoaded(false);
    setConnectionsLoadedData(true);
    let arr = [];
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;
    let url = baseUrl + "/api/connections?search=" + search;
    if (localStorage.userId === "652943c69df2a7301b23c9ab") {
      url = baseUrl + "/api/all_connections?search=" + search;
    }
    await axios
      .get(url)
      .then((res) => {
        // arr = res.data.data;
        arr = res?.data?.data.sort(function (a, b) {
          return Math.floor(b.connection_time) - Math.floor(a.connection_time);
        });
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          for (let i = 0; i < item?.risk_rating_list.length; i++) {
            const el = item?.risk_rating_list[i];
            if (el.color === "bg-[#70ad47]") {
              greenCount++;
            }
            if (el.color === "bg-[#ffc000]") {
              yellowCount++;
            }
            if (el.color === "bg-[#ff0000]") {
              redCount++;
            }
          }
          item.overrallRisk =
            greenCount > 0 && yellowCount === 0 && redCount === 0
              ? "Low"
              : yellowCount === 1 && redCount === 0
              ? "Medium"
              : yellowCount > 1 && redCount === 0
              ? "High"
              : redCount > 0
              ? "High"
              : "";
        }
        if (res.data.users) {
          setUsers(res.data.users);
        }
        setRisk("");
        setUserType("");
        setFill("");
        setRiskRating([]);
        // setIsRiskRating([]);
        setFilterList([]);
        setConnections(arr);
        setConnectionsLoaded(true);
      })
      .finally(() => {
        setConnectionsLoadedData(false);
      });
  };

  const connectInvisibly = async (user) => {
    await axios
      .post(baseUrl + "/api/connect/connect_inv", {
        connect_to: user._id,
      })
      .then((res) => {
        if (res.data.error) {
          message.error(res.data.error.message);
          return;
        }
        message.success("Connected Successfully");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
        message.error("Request failed");
      });
  };

  const getNetworks = () => {
    setNetworksLoaded(false);
    setNetworksLoadedData(true);
    axios
      .get(baseUrl + "/api/user/networks?search=" + search)
      .then((res) => {
        let unique = [];
        let distinct = [];
        let greenCount = 0;
        let yellowCount = 0;
        let redCount = 0;

        res?.data?.data?.forEach((element) => {
          if (element.user_id?._id && !unique[element.user_id?._id]) {
            distinct.push(element);
            unique[element?.user_id?._id] = 1;
          }
        });

        distinct = distinct.sort(function (a, b) {
          return a.created_time > b.created_time
            ? -1
            : a.created_time < b.created_time
            ? 1
            : 0;
        });
        for (let i = 0; i < distinct.length; i++) {
          const item = distinct[i];
          for (let i = 0; i < item?.risk_rating_list.length; i++) {
            const el = item?.risk_rating_list[i];
            if (el.color === "bg-[#70ad47]") {
              greenCount++;
            }
            if (el.color === "bg-[#ffc000]") {
              yellowCount++;
            }
            if (el.color === "bg-[#ff0000]") {
              redCount++;
            }
          }
          item.overrallRisk =
            greenCount > 0 && yellowCount === 0 && redCount === 0
              ? "Low"
              : yellowCount === 1 && redCount === 0
              ? "Medium"
              : yellowCount > 1 && redCount === 0
              ? "High"
              : redCount > 0
              ? "High"
              : "";
        }
        setRisk("");
        setUserType("");
        setFill("");
        setRiskRating([]);
        setFilterList([]);
        setNetworks(distinct);
        setNetworksLoaded(true);
      })
      .finally(() => {
        setNetworksLoadedData(false);
      });
  };

  // connection api
  const removeConnection = (connection) => {
    axios
      .delete(baseUrl + "/api/connect/" + connection?.req_id)
      .then((res) => {
        message.info("Connection Removed Successfully");
        setIsOpen(false);
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const declineReqCddAccess = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection?.req_id + "/decline-access-cdd"
      )
      .then((res) => {
        message.info("Coonection Declined Successfully");
        getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const viewArtpassID = (user) => {
    window.open(baseUrl + "/connectreport/" + user?._id, "_blank");
  };

  const viewCDDReport = (user) => {
    window.open(baseUrl + "/" + user?.cdd?.fileP, "_blank");
  };

  const viewInspectionReport = (user) => {
    window.open(baseUrl + "/" + user.InspectRp.fileUrl, "_blank");
  };

  const viewRequestedCDDReport = (fileUrl) => {
    window.open(baseUrl + "/" + fileUrl, "_blank");
  };

  const viewRequestedInspectionReport = (user) => {
    window.open(baseUrl + "/" + user.InspectRp.fileUrl, "_blank");
    // window.open(baseUrl + "/api/sharedIR/" + user.access_token, "_blank");
  };

  const requestCDDReport = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection.req_id + "/request-access-cdd"
      )
      .then((res) => {
        message.info("CDD Requested Successfully");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptCDDRequest = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection.req_id + "/accept-access-cdd"
      )
      .then((res) => {
        message.info("CDD Request Accepted Successfully");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const declineCDDRequest = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection.req_id + "/decline-access-cdd"
      )
      .then((res) => {
        message.info("CDD Request Declined");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const revokeCDDRequest = (connection) => {
    axios
      .post(
        baseUrl + "/api/connect/" + connection.req_id + "/revoke-access-cdd"
      )
      .then((res) => {
        message.info("CDD Request Successfully Accepted ");
        if (filterList.length > 0) {
          connectionFilter();
        } else {
          getConnections();
        }
        // getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // network api
  const viewArtpassNetworkID = (network) => {
    window.open(baseUrl + "/connectreport/" + network?.user_id?._id, "_blank");
  };

  const networkCDDReport = (network) => {
    axios
      .post(baseUrl + "/api/user/request-cdd-network", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Successfully Requested");
        getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ampRequestCddReport = (network) => {
    axios
      .post(baseUrl + "/api/user/amp-rq-access-cdd", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Successfully Requested");
        getConnections();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const increaseusage = (conn, user) => {
    if (profile?.subscription?.id) {
      let val = {
        rec_id: conn.receiver._id,
        req_id: conn.req_id,
      };
      axios
        .post(baseUrl + "/api/decreaseCountforR/ir", {
          val,
        })
        .then((res) => {
          if (res.data?.error) {
            if (res.data.error.message != "Already Viewed") {
              message.error(res.data?.error.message);
            } else {
              window.open(baseUrl + "/" + user.InspectRp.fileUrl, "_blank");
              getConnections();
            }
          } else {
            window.open(baseUrl + "/" + user.InspectRp.fileUrl, "_blank");
            getConnections();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      message.error("You don't have an active subscription plan");
    }
  };

  const appAcceptShare = (network) => {
    axios
      .post(baseUrl + "/api/user/app-accept-share-cdd", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Request Successfully Accepted");
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptRqCddReport = (network) => {
    axios
      .post(baseUrl + "/api/user/accept-cdd-network", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Request Successfully Accepted");
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const viewNetworkCDDReport = (network) => {
    window.open(baseUrl + "/" + network?.user_id?.cdd?.fileP, "_blank");
  };

  const viewNetworkInspectionReport = (network) => {
    // window.open(baseUrl + "/" + network?.user_id?.InspectRp?.fileUrl, "_blank");
    // window.open(baseUrl + "/api/network/ir?nid=" + network?.network_id +"&token="+network?.secret_token , "_blank");
    // window.open(baseUrl + "/api/network/ir?nid=" + network?.network_id  , "_blank");
    if (profile?.subscription?.id) {
      let val = {
        network_id: network?.network_id,
        // req_id:conn.req_id
      };
      axios
        .post(baseUrl + "/api/decreaseCountforNetwork/irnet", {
          val,
        })
        .then((res) => {
          if (res.data?.error) {
            if (res.data.error.message != "Already Viewed") {
              message.error(res.data?.error.message);
            } else {
              window.open(baseUrl + "/" + res.data.message, "_blank");
              getNetworks();
            }
          } else {
            window.open(baseUrl + "/" + res.data.message, "_blank");
            getNetworks();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      message.error("You don't have an active subscription plan");
    }
  };

  const revokeAccessCddAndIRFromApplicant = (network) => {
    axios
      .post(baseUrl + "/api/user/revoke-amp-access", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Request Remove Successfully");
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeAccessCddAndIRFromAMP = (network) => {
    axios
      .post(baseUrl + "/api/user/remove-cdd-network", {
        secret_token: network?.secret_token,
      })
      .then((res) => {
        message.info("CDD Request Successfully Removed");
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteNetwork = (network) => {
    axios
      .post(baseUrl + "/api/user/delete-cdd-network", network)
      .then((res) => {
        message.info("Network Successfully Removed");
        setIsOpenNetwork(false);
        if (filterList.length > 0) {
          networkFilter();
        } else {
          getNetworks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleButton = (connector, connection) => {
    setIsOpen(true);
    setData(connector);
    setConnectionDetail(connection);
  };

  const handleNetworkButton = (network) => {
    setIsOpenNetwork(true);
    setNetworkDetail(network);
  };

  const addCountryCode = (network) => {
    for (let i = 0; i < countryCodes.length; i++) {
      const el = countryCodes[i];
      const countryCode = el?.iso2code;
      const connectionCountryCode =
        network?.user_id && network?.user_id?.amlReport?.country;
      if (countryCode === connectionCountryCode) {
        setCountryCode(el?.code);
      }
    }
  };

  const addConnectionCountryCode = (connector) => {
    for (let i = 0; i < countryCodes.length; i++) {
      const el = countryCodes[i];
      const countryCode = el?.iso2code;
      const connectionCountryCode = connector?.location?.country;
      if (countryCode === connectionCountryCode) {
        setConnectCountryCode(el?.code);
      }
    }
  };

  const handleFilter = () => {
    setFilterDrawerOpen(true);
    setRisk([]);
    setRiskArr(buttonList);
    setUserType("");
    setFill("");
    setRiskReviewPending([]);
    setExpiredDoc([]);
    setRiskRating([]);
    setFilterList([]);
    // setFilterOpen(true);
  };

  const handleClearFilter = async () => {
    setFilterData((prevData) => ({
      riskLevel: [],
      riskReviewPending: [],
      riskType: [],
      userType: [],
      expiredDoc: [],
      department: [],
      scope: prevData.scope,
    }));
    setCallFilter(true);
    userType = "";
  };

  const removeRisk = async (item) => {
    if (risk.includes(item)) {
      risk = risk.filter((el) => el !== item);
      setRisk(risk);
      connectionFilter();
      networkFilter();
    }
  };

  const handleRiskRating = async (item) => {
    if (riskRating.includes(item)) {
      riskRating = riskRating.filter((el) => el !== item);
      setRiskRating(riskRating);
      connectionFilter();
      networkFilter();
    }
  };

  const handleUserType = async (item) => {
    userType = "";
    setUserType(userType);
    connectionFilter();
    networkFilter();
  };

  const handleRiskReview = async (item) => {
    if (riskReviewPending.includes(item)) {
      riskReviewPending = riskReviewPending.filter((el) => el !== item);
      setRiskReviewPending(riskReviewPending);
      connectionFilter();
      networkFilter();
    }
  };

  const handleRemoveFilter = (filterKey, filterItem) => {
    if (filterData[filterKey].length > 0) {
      if (filterKey === "department") {
        const newFilterData = filterData[filterKey]?.filter(
          (el) => el._id !== filterItem
        );
        setFilterData((prevData) => ({
          ...prevData,
          [filterKey]: newFilterData,
        }));
      }
      // else if (filterKey === "scope") {
      //   setFilterData((prevData) => ({
      //     ...prevData,
      //     [filterKey]: ["allConnections"],
      //   }));
      // }
      else {
        const newFilterData = filterData[filterKey].filter(
          (el) => el !== filterItem
        );
        setFilterData((prevData) => ({
          ...prevData,
          [filterKey]: newFilterData,
        }));
      }
    }
    setCallFilter(true);
  };

  useEffect(() => {
    if (callFilter === true) {
      const connectionFilteredDataList = handleSave(
        connections,
        "connections",
        filterData
      );
      const networkFilteredDataList = handleSave(
        networks,
        "networks",
        filterData
      );

      setFilteredConnections(connectionFilteredDataList);
      setFilteredNetwork(networkFilteredDataList);
      if (filterDrawerOpen) {
        setFilterDrawerOpen(false);
      }
      setCallFilter(false);
    }
  }, [callFilter]);

  // useEffect(() => {
  //   setFilterDataCount(filteredConnections.length + filteredNetwork.length);
  // }, [filteredConnections, filteredNetwork]);

  const connectionFilter = async () => {
    let arr = [];
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;

    const res = await axios
      .post(baseUrl + "/api/connect/filter", {
        risk: risk,
        user_type: userType,
        risk_type: riskRating,
        risk_review_pending: riskReviewPending.length > 0 ? true : false,
        expired_doc: expiredDoc.length > 0 ? true : false,
      })
      .then((res) => {
        // arr = res?.data?.data;
        arr = res?.data?.data.sort(function (a, b) {
          return Math.floor(b.connection_time) - Math.floor(a.connection_time);
        });
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          for (let i = 0; i < item?.risk_rating_list.length; i++) {
            const el = item?.risk_rating_list[i];
            if (el.color === "bg-[#70ad47]") {
              greenCount++;
            }
            if (el.color === "bg-[#ffc000]") {
              yellowCount++;
            }
            if (el.color === "bg-[#ff0000]") {
              redCount++;
            }
          }
          item.overrallRisk =
            greenCount > 0 && yellowCount === 0 && redCount === 0
              ? "Low"
              : yellowCount === 1 && redCount === 0
              ? "Medium"
              : yellowCount > 1 && redCount === 0
              ? "High"
              : redCount > 0
              ? "High"
              : "";
        }

        setFilterOpen(false);
        setFill("");

        setConnections(arr);
        if (
          risk.length !== 0 ||
          userType !== "" ||
          riskRating.length !== 0 ||
          riskReviewPending.length !== 0 ||
          expiredDoc.length !== 0
        ) {
          setConnectionCount(arr.length);
        }
        if (
          risk.length === 0 &&
          userType === "" &&
          riskRating.length === 0 &&
          riskReviewPending.length === 0 &&
          expiredDoc.length === 0
        ) {
          setFilterList([]);
        } else {
          setFilterList(arr);
        }
      })
      .catch((err) => {
        message.error("Something went wrong!");
      });
  };

  const networkFilter = async () => {
    let unique = [];
    let distinct = [];
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;

    const res = await axios
      .post(baseUrl + "/api/network/filter", {
        risk: risk,
        user_type: userType,
        risk_type: riskRating,
        risk_review_pending: riskReviewPending.length > 0 ? true : false,
        expired_doc: expiredDoc.length > 0 ? true : false,
      })
      .then((res) => {
        res?.data?.forEach((element) => {
          if (element.user_id?._id && !unique[element.user_id?._id]) {
            distinct.push(element);
            unique[element?.user_id?._id] = 1;
          }
        });

        distinct = distinct.sort(function (a, b) {
          return a.created_time > b.created_time
            ? -1
            : a.created_time < b.created_time
            ? 1
            : 0;
        });
        for (let i = 0; i < distinct.length; i++) {
          const item = distinct[i];
          for (let i = 0; i < item?.risk_rating_list.length; i++) {
            const el = item?.risk_rating_list[i];
            if (el.color === "bg-[#70ad47]") {
              greenCount++;
            }
            if (el.color === "bg-[#ffc000]") {
              yellowCount++;
            }
            if (el.color === "bg-[#ff0000]") {
              redCount++;
            }
          }
          item.overrallRisk =
            greenCount > 0 && yellowCount === 0 && redCount === 0
              ? "Low"
              : yellowCount === 1 && redCount === 0
              ? "Medium"
              : yellowCount > 1 && redCount === 0
              ? "High"
              : redCount > 0
              ? "High"
              : "";
        }

        setFilterOpen(false);
        setFill("");
        setNetworks(distinct);
        if (
          risk.length !== 0 ||
          userType !== "" ||
          riskRating.length !== 0 ||
          riskReviewPending.length !== 0 ||
          expiredDoc.length !== 0
        ) {
          setNetworkCount(distinct.length);
        }
        if (
          risk.length === 0 &&
          userType === "" &&
          riskRating.length === 0 &&
          riskReviewPending.length === 0 &&
          expiredDoc.length === 0
        ) {
          setFilterList([]);
        } else {
          setFilterList(distinct);
        }
      })
      .catch((err) => {
        message.error("Something went wrong!!");
      });
  };

  const handleSubmit = async () => {
    connectionFilter();
    networkFilter();
  };

  const handleSearch = () => {
    getConnections(search);
    getNetworks(search);
  };

  useEffect(() => {
    // Get current login user data
    if (userData && userData?.utype === "ADMIN") {
      setAccessPII(true);
    } else if (
      userData &&
      userData?._id &&
      userData?.accountType?.type !== RoleManage?.ADMIN
    ) {
      if (userData?.accountType?.type === RoleManage?.SECURITY_ROLE) {
        // If login SECURITY_ROLE / ADMIN
        setAccessPII(userData?.accessPII ? userData?.accessPII : false);
      } else if (userData?.accountType?.type === RoleManage?.MLRO) {
        // If login MLRO
        setAccessPII(true);
      }
    } else {
      setAccessPII(true);
    }
    // Get current login user's admin data
    getAdminProfile().then((adminRes) => {
      if (adminRes?.data) {
        setProfile(adminRes?.data);
      }
    });
  }, []);

  useEffect(() => {
    setLocationkey(location.state);
    getNetworks();
    getConnections();
  }, []);

  useEffect(() => {
    if (
      locationkey &&
      locationkey.filter &&
      networksLoaded &&
      connectionsLoaded
    ) {
      const { filter } = locationkey;
      if (filter) {
        if (filter === "Risk Review Pending") {
          setFilterData((prevData) => ({
            ...prevData,
            riskReviewPending: ["Risk Review Pending"],
          }));
        } else if (filter === "Expired CDD's") {
          setFilterData((prevData) => ({
            ...prevData,
            expiredDoc: ["Expired CDD's"],
          }));
        } else if (filter === "Expired ID's") {
          setFilterData((prevData) => ({
            ...prevData,
            expiredDoc: ["Expired ID's"],
          }));
        }
        setCallFilter(true);
        setShowFilterList(true);
      }
    } else {
    }

    if ((networksLoaded, connectionsLoaded)) {
      setCallFilter(true);
      setShowFilterList(true);
    }
  }, [networksLoaded, connectionsLoaded]);

  // const ShowFilterData = (closeDrawer) => {
  //   const connectionFilteredDataList = handleSave(connections, "connections");
  //   const networkFilteredDataList = handleSave(networks, "networks");

  //   setFilteredConnections(connectionFilteredDataList);
  //   setFilteredNetwork(networkFilteredDataList);
  //   if (closeDrawer) {
  //     setFilterDrawerOpen(false);
  //   }
  // };

  const RenderOnboardingConnections = ({ network, i, isSelfOnboard }) => {
    const InspectionReportButton = ({ network }) => {
      const buttonStyles = network?.update_available
        ? "flex items-center justify-center cursor-pointer !w-36  fs-13 bg-[#ffc100] text-white px-2 h-9 !w-36 rounded-lg"
        : !network?.update_available &&
          "flex items-center justify-center cursor-pointer !w-36  fs-13 bg-primary text-white px-2 h-9 rounded-lg";

      return (
        <div
          className={buttonStyles}
          onClick={() => viewNetworkInspectionReport(network)}
        >
          Inspection Report
        </div>
      );
    };

    return (
      <div key={i}>
        {network?.user_id !== null && (
          <div className="sm:flex w-full gap-4 mb-6">
            <div className="2xl:w-[12%] xl:w-[15%] md:w-[22%] sm:w-[25%] w-full flex justify-start pl-5 sm:pl-0 ms:justify-center items-center mb-4 md:mb-0">
              <img
                className="h-[98px] w-[120px] object-fill"
                // src={baseUrl + "/" +  network?.user_id?.avatar }
                src={baseUrl + "/" + network?.user_id?.avatar}
                alt=""
              />
            </div>

            <div
              className={`2xl:w-[88%] xl:w-[85%] md:w-[78%] sm:w-[75%] w-full bg-[#f0f6fd] rounded-md p-5 grid grid-cols-1 sm:grid-cols-2 xl:gap-3 tab_design ${
                network?.user_id?.removeOn && "border border-danger"
              }`}
            >
              <div className="ms:px-2 flex flex-col justify-center items-start w-full sm:!w-[600px]">
                <div className="mb-4 text-[15px] w-full sm:w-auto">
                  {/* network user company name  */}
                  <p className="font-semibold">
                    {/* {network?.company?.name} */}
                    {network?.user_id?.company?.name}
                  </p>

                  {/* network user full name  */}
                  <p
                    onClick={() => {
                      if (accessPII) {
                        handleNetworkButton(network);
                      } else {
                        return false;
                      }
                    }}
                    className={`font-bold ${
                      ((network?.is_amp == true &&
                        network?.amp_request_cdd?.status == "accepted") ||
                        (network?.is_applicant == true &&
                          network?.amp_request_cdd?.status == "accepted")) &&
                      accessPII &&
                      "text-primary underline cursor-pointer"
                    }`}
                  >
                    {network?.user_id?.fname + " " + network?.user_id?.lname}
                  </p>

                  {/* network user username  */}
                  <p className="font-semibold">{network?.user_id?.username}</p>
                </div>

                {removeAccess.includes(user) && (
                  <div className="flex flex-col gap-2 ms:mb-3 md:mb-0  w-full sm:w-auto cstRedBtn">
                    {/* Decline button  */}
                    {/* <div
                    className="flex items-center justify-center cursor-pointer px-2 h-9 sm:h-7 border border-danger rounded-[4px] text-[15px] sm:text-[14px] text-danger w-full md:w-auto hover:text-white hover:bg-danger"
                    onClick={() => {
                      declineReqCddAccess(network);
                    }}
                  >
                    Decline to share your CDD
                  </div> */}
                    {/* ))} */}

                    {/* Remove Connection button  */}
                    <div
                      className="flex items-center justify-center cursor-pointer fs-13 px-2 h-9 sm:h-7 border whitespace-nowrap border-danger rounded-[4px] text-[15px] sm:text-[14px] text-danger  hover:text-white hover:bg-danger"
                      onClick={() => {
                        deleteNetwork(network);
                      }}
                    >
                      Remove connection
                    </div>

                    {/* Remove Access to my CDD button  */}
                    {network?.is_amp == true &&
                      network?.amp_request_cdd?.status == "accepted" && (
                        <>
                          <div
                            className="flex items-center justify-center cursor-pointer fs-13 px-2 h-9 sm:h-7 border border-danger whitespace-nowrap rounded-[4px] text-[15px] sm:text-[14px]  text-danger hover:text-white hover:bg-danger"
                            onClick={() => {
                              revokeAccessCddAndIRFromApplicant(network);
                            }}
                          >
                            Remove access to my CDD
                          </div>
                        </>
                      )}

                    {/* Remove Access to my CDD button  */}
                    {network?.is_applicant == true &&
                      network?.app_request_cdd?.status == "accepted" && (
                        <>
                          <div
                            className="flex items-center justify-center cursor-pointer fs-13 px-2 h-9 sm:h-7 border border-danger whitespace-nowrap rounded-[4px] text-[15px] sm:text-[14px] text-danger hover:text-white hover:bg-danger"
                            onClick={() => {
                              removeAccessCddAndIRFromAMP(network);
                            }}
                          >
                            Remove Access to my CDD
                          </div>
                        </>
                      )}
                  </div>
                )}
              </div>

              <div
                className={
                  "grid grid-cols-1 xl:grid-cols-2 text-[14px] lg:text-[15px] w-full justify-center items-center gap-2 xl:gap-3"
                }
              >
                {
                  <div className="flex flex-col xl:justify-center justify-start ms:justify-end items-start ms:items-end xl:items-start">
                    {/* Risk review pending  */}
                    {(network?.is_amp == true &&
                      network?.amp_request_cdd?.status == "accepted") ||
                      (network?.is_applicant == true &&
                        network?.amp_request_cdd?.status == "accepted" && (
                          <>
                            {network?.comment_list.length === 0 ||
                            network?.risk_status === "" ? (
                              <>
                                {network?.user_id?.removeOn ? (
                                  <div
                                    className="flex items-center justify-center cursor-pointer fs-13 bg-danger text-white px-2 h-9 rounded-lg !w-40 riskbtn"
                                    onClick={() => {
                                      addCountryCode(network);
                                      handleNetworkButton(network);
                                    }}
                                  >
                                    Pending Removal
                                  </div>
                                ) : (
                                  accessPII && (
                                    <div
                                      className="flex items-center justify-center cursor-pointer fs-13 bg-primary text-white px-2 h-9 rounded-lg !w-40 riskbtn"
                                      onClick={() => {
                                        addCountryCode(network);
                                        handleNetworkButton(network);
                                      }}
                                    >
                                      Risk Review Pending
                                    </div>
                                  )
                                )}

                                <p
                                  className={`font-medium pt-2 text-sm whitespace-nowrap ${
                                    checkExpiry(
                                      network?.user_id?.cdd?.validTill
                                    )
                                      ? "text-danger font-bold"
                                      : ""
                                  }`}
                                >
                                  CDD Report Expires on:{" "}
                                  {network?.user_id?.cdd?.validTill}
                                </p>
                                <p
                                  className={`font-medium pt-2 text-sm whitespace-nowrap ${
                                    checkExpiry(
                                      moment(
                                        network?.user_id?.amlReport?.appData?.info?.idDocs
                                          ?.slice()
                                          ?.reverse()
                                          ?.find(
                                            (a) => a.idDocType && a.validUntil
                                          )?.validUntil
                                      ).format("DD MMM YYYY")
                                    )
                                      ? "text-danger font-bold"
                                      : ""
                                  }`}
                                >
                                  ID Expiration Date:{" "}
                                  {moment(
                                    network?.user_id?.amlReport?.appData?.info?.idDocs
                                      ?.slice()
                                      ?.reverse()
                                      ?.find((a) => a.idDocType && a.validUntil)
                                      ?.validUntil
                                  ).format("DD MMM YYYY")}
                                </p>
                              </>
                            ) : (
                              <>
                                {network?.user_id?.removeOn ? (
                                  <div
                                    className="flex items-center justify-center cursor-pointer fs-13 bg-danger text-white px-2 h-9 rounded-lg !w-40 riskbtn"
                                    onClick={() => {
                                      addCountryCode(network);
                                      handleNetworkButton(network);
                                    }}
                                  >
                                    Pending Removal
                                  </div>
                                ) : (
                                  <>
                                    {network?.overrall_Risk === "Low" &&
                                      accessPII && (
                                        <div
                                          className="flex items-center justify-center cursor-pointer fs-13 text-[#70ad47] border-[#70ad47] border-2 px-2 h-9 rounded-lg !w-36 riskbtn"
                                          onClick={() => {
                                            addCountryCode(network);
                                            handleNetworkButton(network);
                                          }}
                                        >
                                          Low Risk
                                        </div>
                                      )}

                                    {network?.overrall_Risk === "Medium" &&
                                      accessPII && (
                                        <div
                                          className="flex items-center justify-center cursor-pointer fs-13 text-[#ffc000] border-[#ffc000] border-2 px-2 h-9 rounded-lg !w-36 riskbtn"
                                          onClick={() => {
                                            addCountryCode(network);
                                            handleNetworkButton(network);
                                          }}
                                        >
                                          Medium Risk
                                        </div>
                                      )}

                                    {network?.overrall_Risk === "High" &&
                                      accessPII && (
                                        <div
                                          className="flex items-center justify-center cursor-pointer fs-13 text-[#ff0000] border-[#ff0000] border-2 px-2 h-9 rounded-lg !w-36 riskbtn"
                                          onClick={() => {
                                            addCountryCode(network);
                                            handleNetworkButton(network);
                                          }}
                                        >
                                          High Risk
                                        </div>
                                      )}
                                  </>
                                )}

                                {network?.risk_status !== "" && (
                                  <>
                                    <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                      Last assessed:
                                      {moment
                                        .unix(network?.updated_risk_time / 1000)
                                        .format("DD MMM YYYY")}{" "}
                                    </p>
                                    <p
                                      className={`font-medium pt-2 text-sm whitespace-nowrap
                                    ${
                                      checkExpiry(
                                        network?.user_id?.cdd?.validTill
                                      )
                                        ? "text-danger font-bold"
                                        : ""
                                    }`}
                                    >
                                      CDD Report Expires on:{" "}
                                      {network?.user_id?.cdd?.validTill}
                                    </p>
                                    <p
                                      className={`font-medium pt-2 text-sm whitespace-nowrap
                                    ${
                                      checkExpiry(
                                        moment(
                                          network?.user_id?.amlReport?.appData?.info?.idDocs
                                            ?.slice()
                                            ?.reverse()
                                            ?.find(
                                              (a) => a.idDocType && a.validUntil
                                            )?.validUntil
                                        ).format("DD MMM YYYY")
                                      )
                                        ? "text-danger font-bold"
                                        : ""
                                    }`}
                                    >
                                      ID Expiration Date:{" "}
                                      {moment(
                                        network?.user_id?.amlReport?.appData?.info?.idDocs
                                          ?.slice()
                                          ?.reverse()
                                          ?.find(
                                            (a) => a.idDocType && a.validUntil
                                          )?.validUntil
                                      ).format("DD MMM YYYY")}
                                    </p>
                                  </>
                                )}
                              </>
                            )}
                            {network.hold && (
                              <div
                                onClick={() => {
                                  unHoldUserNetwork(network);
                                }}
                                className="flex items-center justify-center cursor-pointer fs-13 bg-danger text-white px-2 mt-5 h-9 !w-36 rounded-lg"
                              >
                                On Hold
                              </div>
                            )}
                          </>
                        ))}
                  </div>
                }

                {/* flex flex-col xl:justify-center justify-start ms:justify-end items-start ms:items-end xl:items-center */}
                {/* justify-start ms:justify-end items-start ms:items-end xl:items-center */}

                <div
                  className={`flex flex-col md:flex-col xl:flex-row justify-start ms:justify-end xl:justify-end items-start ms:items-end xl:items-end gap-2 xl:gap-3`}
                >
                  <div className="flex flex-col order-2 md:order-2 xl:order-1 gap-3 justify-center items-start ms:items-end mob:items-center cstBtn">
                    {/* Accept to Share CDD Report button  */}
                    {network?.is_amp == true &&
                      network?.amp_request_cdd?.status == "pending" && (
                        <>
                          <div
                            className="flex items-center justify-center cursor-pointer fs-13 bg-primary text-white whitespace-nowrap xl:px-2 h-9 rounded-lg px-2"
                            onClick={() => {
                              appAcceptShare(network);
                            }}
                          >
                            Accept to share your CDD
                          </div>
                        </>
                      )}

                    {/* Accept to Share CDD Report button  */}
                    {network?.is_applicant == true &&
                      network?.app_request_cdd?.status == "pending" && (
                        <>
                          <div
                            className="flex items-center justify-center cursor-pointer fs-13 bg-primary text-white whitespace-nowrap xl:px-2 h-9 rounded-lg px-2"
                            onClick={() => {
                              acceptRqCddReport(network);
                            }}
                          >
                            Accept to share your CDD
                          </div>
                        </>
                      )}

                    {/* CDD request pending button  */}
                    {(network?.is_amp == true &&
                      network?.app_request_cdd?.status == "pending") ||
                      (network?.is_applicant == true &&
                        network?.amp_request_cdd?.status == "pending" && (
                          <div className="flex items-center justify-center bg-gray-300 hover:cursor-not-allowed text-primary px-2 h-9 rounded-lg">
                            CDD Request Pending
                          </div>
                        ))}
                  </div>

                  <div className="flex flex-col justify-center order-1 md:order-1 xl:order-2 items-start ms:items-end gap-3 mob:items-center cstBtnWidth">
                    {/* artpass ID button  */}
                    {!isSelfOnboard && (
                      <div
                        className="flex items-center justify-center cursor-pointer !w-36 fs-13 border border-[#86c1f4] text-primary px-2 h-9 rounded-lg"
                        onClick={() => {
                          viewArtpassNetworkID(network);
                        }}
                      >
                        iPass ID
                      </div>
                    )}

                    {/* CDD Report &   Inspection Report button for sender  */}
                    {(network?.is_amp == true &&
                      network?.amp_request_cdd?.status == "accepted") ||
                      (network?.is_applicant == true &&
                        network?.amp_request_cdd?.status == "accepted" &&
                        accessPII && (
                          <>
                            {!isSelfOnboard && (
                              <div
                                className="flex items-center justify-center cursor-pointer !w-36 fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg
                    "
                                onClick={() => {
                                  viewNetworkCDDReport(network);
                                }}
                              >
                                CDD Report
                              </div>
                            )}

                            {/* {network?.user_id?.InspectRp2?.length > 0 ? (
                              <>
                                {network?.update_available && (
                                  <div
                                    className="flex items-center justify-center cursor-pointer !w-36 fs-13 bg-[#ffc100] text-white px-2 h-9 !w-36 rounded-lg"
                                    onClick={() => {
                                      viewNetworkInspectionReport(network);
                                    }}
                                  >
                                    Inspection Report
                                  </div>
                                )}
                                {!network?.update_available && (
                                  <div
                                    className="flex items-center justify-center cursor-pointer !w-36 fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                    onClick={() => {
                                      viewNetworkInspectionReport(network);
                                    }}
                                  >
                                    Inspection Report
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="xl:ml-7 ">
                                Inspection Report in preparation
                              </div>
                            )} */}

                            {network?.user_id?.inspectionReportRequest ? (
                              network?.user_id?.InspectRp2?.length > 0 ? (
                                <InspectionReportButton network={network} />
                              ) : (
                                <div className="xl:ml-7 ">
                                  Inspection Report in preparation
                                </div>
                              )
                            ) : (
                              <InspectionReportButton network={network} />
                            )}

                            {/* <div
                                   className="flex items-center justify-center cursor-pointer !w-36  fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                   onClick={() => {
                                     viewNetworkInspectionReport(network);
                                   }}
                                 >
                                   Inspection Report
                                 </div> */}

                            {/* <div
                              className="flex items-center justify-center cursor-pointer !w-36  fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                              onClick={() => {
                                viewNetworkCompleteIR(network);
                              }}
                            >
                              Complete IR
                            </div> */}
                          </>
                        ))}

                    {/* Request CDD Report button  */}
                    {/* {network?.is_amp == true &&
                    !network?.app_request_cdd && (
                      <div
                        className="flex items-center justify-center cursor-pointer border border-[#86c1f4] text-primary px-2  h-9 w-full  rounded-lg"
                        onClick={() => {
                          networkCDDReport(network);
                        }}
                      >
                        Request CDD Report
                      </div>
                    )} */}
                    {/* Request CDD Report button  */}
                    {/* {network?.is_applicant == true &&
                    !network?.amp_request_cdd && (
                      <div
                        className="flex items-center justify-center cursor-pointer border border-[#86c1f4] text-primary px-2  h-9 w-full  rounded-lg"
                        onClick={() => {
                          ampRequestCddReport(network);
                        }}
                      >
                        Request CDD Report
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getRiskTypeFilterLabel = (riskTypeValue) => {
    const riskTypeValueLabelMap = {
      Region: "Territorial Risk",
      "Activity/Funds": "Activity Risk",
      "Sanctions Screening": "Sanctions Screening",
      Documents: "Directors/UBO's Screening",
      "Adverse Media": "Website/Online Activity",
      PEP: "PEP",
    };

    return riskTypeValueLabelMap[riskTypeValue];
  };

  return (
    <>
      {/* <FilterModal
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
            risk={risk}
            setRisk={setRisk}
            userType={userType}
            riskReviewPending={riskReviewPending}
            setRiskReviewPending={setRiskReviewPending}
            expiredDoc={expiredDoc}
            setExpiredDoc={setExpiredDoc}
            setUserType={setUserType}
            riskRating={riskRating}
            setRiskRating={setRiskRating}
            handleSubmit={handleSubmit}
            fill={fill}
            setFill={setFill}
            isRiskRating={isRiskRating}
            setIsRiskRating={setIsRiskRating}
            riskArr={riskArr}
            setRiskArr={setRiskArr}
          /> */}

      {isOpen &&
        ((profile?._id === connectionDetail?.receiver?._id &&
          connectionDetail?.sender?.utype === "BUSINESS") ||
          (profile?._id === connectionDetail?.sender?._id &&
            connectionDetail?.receiver?.utype === "BUSINESS")) && (
          <RiskReviewPendingBusinessDrawer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={data}
            connectionDetail={connectionDetail}
            getConnections={getConnections}
            connections={connections}
            connectCountryCode={connectCountryCode}
            connectionFilter={connectionFilter}
            networkFilter={networkFilter}
            filterList={filterList}
            optionVal={optionVal}
            adminDetails={profile}
            removeConnection={removeConnection}
            connectionsLoadedData={connectionsLoadedData}
          />
        )}

      {isOpen &&
        ((profile?._id === connectionDetail?.receiver?._id &&
          connectionDetail?.sender?.utype === "PATRON") ||
          (profile?._id === connectionDetail?.sender?._id &&
            connectionDetail?.receiver?.utype === "PATRON")) && (
          <RiskReviewPendingPatronDrawer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={data}
            connectionDetail={connectionDetail}
            getConnections={getConnections}
            connections={connections}
            connectCountryCode={connectCountryCode}
            connectionFilter={connectionFilter}
            networkFilter={networkFilter}
            filterList={filterList}
            optionVal={optionVal}
            adminDetails={profile}
            removeConnection={removeConnection}
            connectionsLoadedData={connectionsLoadedData}
          />
        )}

      {/* <RiskReviewPendingModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={data}
            connectionDetail={connectionDetail}
            getConnections={getConnections}
            connections={connections}
            connectCountryCode={connectCountryCode}
            connectionFilter={connectionFilter}
            networkFilter={networkFilter}
            filterList={filterList}
            optionVal={optionVal}
          /> */}
      {isOpenNetwork &&
        (networkDetail?.user_id?.utype === "BUSINESS" ? (
          <NetworkRiskReviewPendingBusinessDrawer
            isOpen={isOpenNetwork}
            setIsOpen={setIsOpenNetwork}
            networkDetail={networkDetail}
            getNetworks={getNetworks}
            networks={networks}
            countryCode={countryCode}
            connectionFilter={connectionFilter}
            networkFilter={networkFilter}
            filterList={filterList}
            optionVal={optionVal}
            adminDetails={profile}
            deleteNetwork={deleteNetwork}
            networksLoadedData={networksLoadedData}
          />
        ) : (
          networkDetail?.user_id?.utype === "PATRON" && (
            <NetworkRiskReviewPendingPatronDrawer
              isOpen={isOpenNetwork}
              setIsOpen={setIsOpenNetwork}
              networkDetail={networkDetail}
              getNetworks={getNetworks}
              networks={networks}
              countryCode={countryCode}
              connectionFilter={connectionFilter}
              networkFilter={networkFilter}
              filterList={filterList}
              optionVal={optionVal}
              adminDetails={profile}
              deleteNetwork={deleteNetwork}
              networksLoadedData={networksLoadedData}
            />
          )
        ))}
      {/* <NetworkRiskReviewPendingModal
            isOpen={isOpenNetwork}
            setIsOpen={setIsOpenNetwork}
            networkDetail={networkDetail}
            getNetworks={getNetworks}
            networks={networks}
            countryCode={countryCode}
            connectionFilter={connectionFilter}
            networkFilter={networkFilter}
            filterList={filterList}
            optionVal={optionVal}
          /> */}
      <div
        wrap="true"
        className="text-right flex justify-between sm:flex-row flex-col gap-y-3 sm:gap-y-0"
      >
        <div className="flex justify-start items-center gap-3">
          <input
            type="text"
            className="w-auto md:w-56 h-8 rounded-md border border-[#d9d9d9] !outline-none focus:!outline-none focus:border-[#d9d9d9]"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div
            type="button"
            className="flex items-center justify-center cursor-pointer text-xs border-primary border font-semibold tracking-wide text-primary w-28 px-2 h-8 rounded-lg"
            onClick={handleSearch}
          >
            Search
          </div>
        </div>

        <div className="flex items-center">
          {accessPII && (
            <>
              {connectionsLoaded && networksLoaded && (
                <div className="flex justify-start items-center">
                  {combinedSelectedFiltersForFilterBtn.length === 0 &&
                  userType === "" ? (
                    <div
                      type="button"
                      className="flex items-center justify-center cursor-pointer text-xs border-primary border font-semibold tracking-wide text-primary w-28 px-2 h-8 rounded-lg"
                      onClick={handleFilter}
                    >
                      Filter
                    </div>
                  ) : (
                    <div
                      type="button"
                      className="flex items-center justify-center cursor-pointer text-xs border-primary border font-semibold tracking-wide text-primary w-36 px-2 h-8 rounded-lg"
                      onClick={handleClearFilter}
                    >
                      Clear filter selection
                    </div>
                  )}
                  {/* {filterList.length === 0 &&
                    risk.length === 0 &&
                    userType === "" &&
                    riskRating.length === 0 &&
                    riskReviewPending.length === 0 &&
                    expiredDoc.length === 0 ? (
                      <div
                        type="button"
                        className="flex items-center justify-center cursor-pointer text-xs border-primary border font-semibold tracking-wide text-primary w-28 px-2 h-8 rounded-lg"
                        onClick={handleFilter}
                      >
                        Filter
                      </div>
                    ) : (
                      <div
                        type="button"
                        className="flex items-center justify-center cursor-pointer text-xs border-primary border font-semibold tracking-wide text-primary w-36 px-2 h-8 rounded-lg"
                        onClick={handleClearFilter}
                      >
                        Clear filter selection
                      </div>
                    )} */}
                </div>
              )}
            </>
          )}
          {/* </div> */}

          {/* <Select
                style={{
                  width: 180,
                  textAlign: "center",
                }}
                placeholder={"Sort: "}
                onChange={handleChange}
                options={[
                  // {
                  //   value: "Aplhabatically",
                  //   label: "Aplhabatically",
                  // },
                  {
                    value: "Old - New",
                    label: "Old - New",
                  },
                  {
                    value: "New - Old",
                    label: "New - Old",
                  },
                  // {
                  //   value: "Private Person Only",
                  //   label: "Private Person Only",
                  // },
                  // {
                  //   value: "Legal Entities Only",
                  //   label: "Legal Entities Only",
                  // },
                ]}
              /> */}
        </div>
      </div>
      {filterDrawerOpen && (
        <WhitelistedFilterDrawer
          filterDrawerOpen={filterDrawerOpen}
          filterDrawerOpenClose={filterDrawerOpenClose}
          filterData={filterData}
          setFilterData={setFilterData}
          setCount={setCount}
          count={count}
          // ShowFilterData={ShowFilterData}
          setShowFilterList={setShowFilterList}
          userData={userData}
          setSettingsFilterValueChange={setSettingsFilterValueChange}
          setCallFilter={setCallFilter}
        />
      )}
      {showFilterList === true && combinedSelectedFilters.length > 0 && (
        <div className="flex gap-5 py-5">
          <div>
            {
              <p className="w-36 pl-5 font-semibold whitespace-nowrap pr-5">
                Results: {filteredConnections.length + filteredNetwork.length}{" "}
              </p>
            }
          </div>

          <div className="flex justify-start gap-4 pb-2 px-2 w-full break-all flex-wrap">
            {Object.keys(filterData).map((filterKey, index) => {
              return (
                <React.Fragment key={index}>
                  {!filterDrawerOpen && (
                    <>
                      {(filterKey === "riskLevel" ||
                        filterKey === "riskReviewPending" ||
                        filterKey === "riskType" ||
                        filterKey === "userType" ||
                        filterKey === "expiredDoc") &&
                        filterData[filterKey].length > 0 &&
                        filterData[filterKey].map((filter, index) => (
                          <div
                            key={index}
                            type="button"
                            className="fs-13 gap-2 w-50 border border-black text-black px-4 h-8 rounded-lg font-semibold tracking-wide flex justify-between items-center"
                          >
                            {filterKey === "riskType"
                              ? getRiskTypeFilterLabel(filter)
                              : filter}{" "}
                            {filterKey === "riskLevel" && "Risk"}
                            <IoMdClose
                              size={15}
                              onClick={() =>
                                handleRemoveFilter(filterKey, filter)
                              }
                            />
                          </div>
                        ))}
                      {filterKey === "department" &&
                        filterData[filterKey].length > 0 &&
                        filterData[filterKey].map((departmentData, index) => (
                          <div
                            key={index}
                            type="button"
                            className="fs-13 gap-2 w-50 border border-black text-black px-4 h-8 rounded-lg font-semibold tracking-wide flex justify-between items-center"
                          >
                            {departmentData.department}
                            <IoMdClose
                              size={15}
                              onClick={() =>
                                handleRemoveFilter(
                                  filterKey,
                                  departmentData._id
                                )
                              }
                            />
                          </div>
                        ))}
                    </>
                  )}
                  {filterKey === "scope" &&
                    filterData[filterKey].length > 0 &&
                    filterData[filterKey].map((scopeData, index) => (
                      <div
                        key={index}
                        type="button"
                        className="fs-13 gap-2 w-50 border border-black text-black px-4 h-8 rounded-lg font-semibold tracking-wide flex justify-between items-center"
                      >
                        {scopeData === "inScope"
                          ? "In Scope"
                          : scopeData === "outOfScope"
                          ? "Out Of Scope"
                          : "All Connections"}
                        {/* <IoMdClose
                              size={15}
                              onClick={() =>
                                handleRemoveFilter(filterKey, scopeData)
                              }
                            /> */}
                      </div>
                    ))}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
      {filteredConnections?.length === 0 && filteredNetwork?.length === 0 ? (
        <>
          <NotFoundData
            message={
              <>
                Connection requests to access your customers iPass ID and CDD
                Report will appear here.
              </>
            }
          />
        </>
      ) : (
        <>
          {filteredConnections.length !== 0 && (
            <p className="font-bold text-lg pb-5 pt-3 flex justify-center items-center w-full tracking-wide">
              Scan & Connect Connections
            </p>
          )}
          {(filteredConnections || []).map((connection, i) => {
            // const connector =
            //   profile?._id == connection?.sender._id
            //     ? connection?.receiver
            //     : connection?.sender;
            const connector =
              profile?._id == connection?.sender._id
                ? {
                    ...{
                      ...connection?.receiver,
                      ...connection?.sender_req_cdd,
                    },
                  }
                : {
                    ...{
                      ...connection?.sender,
                      ...connection?.receiver_req_cdd,
                    },
                  };
            return (
              <div className={`sm:flex  w-full gap-4 mb-6 `} key={i}>
                <div
                  className="2xl:w-[12%] xl:w-[15%] md:w-[22%] sm:w-[25%] w-full flex justify-start pl-5 sm:pl-0 ms:justify-center items-center  mb-4 md:mb-0 "
                  style={{ flexDirection: "column" }}
                >
                  <img
                    className="h-[98px] w-[120px] object-fill"
                    // src={baseUrl + "/" + connector?.avatar}
                    src={baseUrl + "/" + connector?.avatar}
                    alt=""
                  />
                </div>

                <div
                  className={`2xl:w-[88%] xl:w-[85%] md:w-[78%] sm:w-[75%] w-full bg-[#f0f6fd] rounded-md p-5 tab_design ${
                    ((profile?._id === connection?.receiver?._id &&
                      connection?.sender.removeOn) ||
                      (profile?._id === connection?.sender?._id &&
                        connection?.receiver.removeOn)) &&
                    "border border-danger"
                  }`}
                >
                  <div className="ms:px-2 flex flex-col justify-center items-start w-full sm:!w-[600px]">
                    <div className="mb-4 text-[15px] w-full sm:w-auto">
                      {/* connector company name  */}
                      <p className="font-semibold">
                        {connector?.company?.name}
                      </p>

                      {/* connector full name  */}
                      <p
                        onClick={() => {
                          if (
                            (profile?._id == connection?.receiver?._id &&
                              connection?.receiver_req_cdd &&
                              connection?.receiver_req_cdd?.status ==
                                "accepted") ||
                            (profile?._id == connection?.sender?._id &&
                              connection?.sender_req_cdd &&
                              connection?.sender_req_cdd?.status ==
                                "accepted" &&
                              accessPII)
                          ) {
                            handleButton(connector, connection);
                          } else {
                            return false;
                          }
                        }}
                        className={`font-bold
                               ${
                                 ((profile?._id == connection?.receiver?._id &&
                                   connection?.receiver_req_cdd &&
                                   connection?.receiver_req_cdd?.status ==
                                     "accepted") ||
                                   (profile?._id == connection?.sender?._id &&
                                     connection?.sender_req_cdd &&
                                     connection?.sender_req_cdd?.status ==
                                       "accepted")) &&
                                 accessPII &&
                                 "text-primary underline cursor-pointer"
                               }`}
                      >
                        {connector?.fname + " " + connector?.lname}
                      </p>

                      {/* connector username  */}
                      <p className="font-semibold">{connector?.username} </p>
                    </div>

                    {removeAccess.includes(user) && (
                      <div className="flex flex-col gap-2 ms:mb-3 md:mb-0  w-full sm:w-auto cstRedBtn">
                        {/* Decline button  */}
                        {/* {(profile?._id == connection?.sender?._id &&
                            connection?.receiver_req_cdd?.status == "pending") ||
                            (profile?._id == connection?.receiver?._id &&
                              connection?.sender_req_cdd?.status == "pending" && (
                                <div
                                  className="flex items-center justify-center cursor-pointer px-2 h-9 sm:h-7 border border-danger rounded-[4px] text-[15px] sm:text-[14px] text-danger w-full md:w-auto hover:text-white hover:bg-danger"
                                  onClick={() => {
                                    declineReqCddAccess(connection);
                                  }}
                                >
                                  Decline to share your CDD
                                </div>
                              ))} */}

                        {/* Remove Connection button  */}

                        <div
                          className="flex items-center justify-center cursor-pointer fs-13 px-2 h-9 sm:h-7 border whitespace-nowrap border-danger rounded-[4px] text-[15px] sm:text-[14px] text-danger  hover:text-white hover:bg-danger"
                          onClick={() => {
                            removeConnection(connection);
                          }}
                        >
                          Remove connection
                        </div>

                        {/* Remove Access to my CDD button  */}
                        {((profile?._id == connection?.sender._id &&
                          connection?.receiver_req_cdd &&
                          Object.keys(connection?.receiver_req_cdd).length >
                            0 &&
                          connection?.receiver_req_cdd?.status == "accepted") ||
                          (profile._id == connection?.receiver?._id &&
                            connection?.sender_req_cdd &&
                            Object.keys(connection?.sender_req_cdd).length >
                              0 &&
                            connection?.sender_req_cdd?.status ==
                              "accepted")) && (
                          <>
                            <div
                              className="flex items-center justify-center cursor-pointer fs-13 px-2 h-9 sm:h-7 border border-danger whitespace-nowrap rounded-[4px] text-[15px] sm:text-[14px] text-danger hover:text-white hover:bg-danger"
                              onClick={() => {
                                revokeCDDRequest(connection);
                              }}
                            >
                              Remove access to my CDD
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      "grid grid-cols-1 xl:grid-cols-2 text-[14px] lg:text-[15px] w-full justify-center xl:justify-end items-start gap-2 xl:gap-3"
                    }
                  >
                    {
                      <div className="flex flex-col xl:justify-end justify-start ms:justify-end items-start ms:items-end xl:items-start">
                        {/* Risk review pending  */}
                        {((profile?._id == connection?.receiver?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "accepted") ||
                          (profile?._id == connection?.sender?._id &&
                            connection?.sender_req_cdd &&
                            connection?.sender_req_cdd?.status ==
                              "accepted")) && (
                          <>
                            {connection &&
                            connection?.comment_list?.length === 0 &&
                            connection?.risk_status === "" ? (
                              <>
                                {(profile?._id === connection?.receiver?._id &&
                                  connection?.sender.removeOn) ||
                                (profile?._id === connection?.sender?._id &&
                                  connection?.receiver.removeOn) ? (
                                  <div
                                    className="flex items-center justify-center cursor-pointer fs-13 bg-danger text-white px-2 h-9 rounded-lg !w-40 riskbtn"
                                    onClick={() => {
                                      addConnectionCountryCode(connector);
                                      handleButton(connector, connection);
                                    }}
                                  >
                                    Pending Removal
                                  </div>
                                ) : (
                                  accessPII && (
                                    <div
                                      className="flex items-center justify-center cursor-pointer fs-13 bg-primary text-white px-2 h-9 rounded-lg !w-40 riskbtn"
                                      onClick={() => {
                                        addConnectionCountryCode(connector);
                                        handleButton(connector, connection);
                                      }}
                                    >
                                      {/* main btn */}
                                      Risk Review Pending
                                    </div>
                                  )
                                )}

                                <p
                                  className={`font-medium pt-2 text-sm whitespace-nowrap ${
                                    checkExpiry(
                                      profile?._id === connection?.sender?._id
                                        ? connection?.receiver?.cdd?.validTill
                                        : connection?.sender?.cdd?.validTill
                                    )
                                      ? "text-danger font-bold"
                                      : ""
                                  }`}
                                >
                                  CDD Report Expires on:{" "}
                                  {/* {connection?.sender?.cdd?.validTill} */}
                                  {profile?._id === connection?.sender?._id
                                    ? connection?.receiver?.cdd?.validTill
                                    : connection?.sender?.cdd?.validTill}
                                </p>
                                <p
                                  className={`font-medium pt-2 text-sm whitespace-nowrap ${
                                    checkExpiry(
                                      profile?._id === connection?.sender?._id
                                        ? moment(
                                            connection?.receiver?.amlReport?.appData?.info?.idDocs
                                              ?.slice()
                                              ?.reverse()
                                              ?.find(
                                                (a) =>
                                                  a.idDocType && a.validUntil
                                              )?.validUntil
                                          ).format("DD MMM YYYY")
                                        : moment(
                                            connection?.sender?.amlReport?.appData?.info?.idDocs
                                              ?.slice()
                                              ?.reverse()
                                              ?.find(
                                                (a) =>
                                                  a.idDocType && a.validUntil
                                              )?.validUntil
                                          ).format("DD MMM YYYY")
                                    )
                                      ? "text-danger font-bold"
                                      : ""
                                  }`}
                                >
                                  ID Expiration Date:{" "}
                                  {profile?._id === connection?.sender?._id
                                    ? moment(
                                        connection?.receiver?.amlReport?.appData?.info?.idDocs
                                          ?.slice()
                                          ?.reverse()
                                          ?.find(
                                            (a) => a.idDocType && a.validUntil
                                          )?.validUntil
                                      ).format("DD MMM YYYY")
                                    : moment(
                                        connection?.sender?.amlReport?.appData?.info?.idDocs
                                          ?.slice()
                                          ?.reverse()
                                          ?.find(
                                            (a) => a.idDocType && a.validUntil
                                          )?.validUntil
                                      ).format("DD MMM YYYY")}
                                </p>
                              </>
                            ) : (
                              <>
                                {(profile?._id === connection?.receiver?._id &&
                                  connection?.sender.removeOn) ||
                                (profile?._id === connection?.sender?._id &&
                                  connection?.receiver.removeOn) ? (
                                  <div
                                    className="flex items-center justify-center cursor-pointer fs-13 bg-danger text-white px-2 h-9 rounded-lg !w-40 riskbtn"
                                    onClick={() => {
                                      addConnectionCountryCode(connector);
                                      handleButton(connector, connection);
                                    }}
                                  >
                                    Pending Removal
                                  </div>
                                ) : (
                                  <>
                                    {connection?.overrall_Risk === "Low" &&
                                      accessPII && (
                                        <div
                                          className="flex justify-center items-center cursor-pointer fs-13 text-[#70ad47] border-[#70ad47] border-2 px-2 h-9 rounded-lg !w-36 riskbtn"
                                          onClick={() => {
                                            addConnectionCountryCode(connector);
                                            handleButton(connector, connection);
                                          }}
                                        >
                                          Low Risk
                                        </div>
                                      )}

                                    {connection?.overrall_Risk === "Medium" &&
                                      accessPII && (
                                        <div
                                          className="flex justify-center items-center fs-13 cursor-pointer text-[#ffc000] border-[#ffc000] border-2 px-2 h-9 rounded-lg !w-36 riskbtn"
                                          onClick={() => {
                                            addConnectionCountryCode(connector);
                                            handleButton(connector, connection);
                                          }}
                                        >
                                          Medium Risk
                                        </div>
                                      )}

                                    {connection?.overrall_Risk === "High" &&
                                      accessPII && (
                                        <div
                                          className="flex justify-center items-center fs-13 cursor-pointer text-[#ff0000] border-[#ff0000] border-2 px-2 h-9 rounded-lg !w-36 riskbtn"
                                          onClick={() => {
                                            addConnectionCountryCode(connector);
                                            handleButton(connector, connection);
                                          }}
                                        >
                                          High Risk
                                        </div>
                                      )}
                                  </>
                                )}

                                {connection?.risk_status !== "" && (
                                  <>
                                    <p className="font-medium pt-2 text-sm whitespace-nowrap">
                                      Last assessed:{" "}
                                      {moment
                                        .unix(
                                          connection?.updated_risk_time / 1000
                                        )
                                        .format("DD MMM YYYY")}{" "}
                                    </p>
                                    <p
                                      className={`font-medium pt-2 text-sm whitespace-nowrap ${
                                        checkExpiry(
                                          profile?._id ===
                                            connection?.sender?._id
                                            ? connection?.receiver?.cdd
                                                ?.validTill
                                            : connection?.sender?.cdd?.validTill
                                        )
                                          ? "text-danger font-bold"
                                          : ""
                                      }`}
                                    >
                                      CDD Report Expires on:{" "}
                                      {/* {connection?.sender?.cdd?.validTill} */}
                                      {profile?._id === connection?.sender?._id
                                        ? connection?.receiver?.cdd?.validTill
                                        : connection?.sender?.cdd?.validTill}
                                    </p>
                                    <p
                                      className={`font-medium pt-2 text-sm whitespace-nowrap ${
                                        checkExpiry(
                                          profile?._id ===
                                            connection?.sender?._id
                                            ? moment(
                                                connection?.receiver?.amlReport?.appData?.info?.idDocs
                                                  ?.slice()
                                                  ?.reverse()
                                                  ?.find(
                                                    (a) =>
                                                      a.idDocType &&
                                                      a.validUntil
                                                  )?.validUntil
                                              ).format("DD MMM YYYY")
                                            : moment(
                                                connection?.sender?.amlReport?.appData?.info?.idDocs
                                                  ?.slice()
                                                  ?.reverse()
                                                  ?.find(
                                                    (a) =>
                                                      a.idDocType &&
                                                      a.validUntil
                                                  )?.validUntil
                                              ).format("DD MMM YYYY")
                                        )
                                          ? "text-danger font-bold"
                                          : ""
                                      }`}
                                    >
                                      ID Expiration Date:{" "}
                                      {profile?._id === connection?.sender?._id
                                        ? moment(
                                            connection?.receiver?.amlReport?.appData?.info?.idDocs
                                              ?.slice()
                                              ?.reverse()
                                              ?.find(
                                                (a) =>
                                                  a.idDocType && a.validUntil
                                              )?.validUntil
                                          ).format("DD MMM YYYY")
                                        : moment(
                                            connection?.sender?.amlReport?.appData?.info?.idDocs
                                              ?.slice()
                                              ?.reverse()
                                              ?.find(
                                                (a) =>
                                                  a.idDocType && a.validUntil
                                              )?.validUntil
                                          ).format("DD MMM YYYY")}
                                    </p>
                                  </>
                                )}
                              </>
                            )}
                            {!connector.hold &&
                              profile._id == "652943c69df2a7301b23c9ab" && (
                                <div
                                  className="flex items-center justify-center cursor-pointer fs-13 text-primary border-[#007aea] mt-5 border-2 px-2 h-9 rounded-lg !w-36 riskbtn"
                                  onClick={() => {
                                    holdUser(connector);
                                  }}
                                >
                                  Activate On Hold
                                </div>
                              )}
                            {connector.hold && (
                              <div
                                onClick={() => {
                                  unHoldUser(connector);
                                }}
                                className="flex items-center justify-center cursor-pointer fs-13 bg-danger text-white px-2 mt-5 h-9 !w-36 rounded-lg"
                              >
                                On Hold
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    }

                    <div className="flex flex-col md:flex-col xl:flex-row xl:justify-end justify-start ms:justify-end items-start ms:items-end xl:items-center gap-2 xl:gap-3">
                      <div className="flex flex-col order-2 md:order-2 xl:order-1 gap-3 justify-center items-start ms:items-end mob:items-center cstBtn">
                        {/* Share your CDD Report button  */}
                        {/* ACCEPT FOR SENDER */}
                        {profile?._id == connection?.sender?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "pending" && (
                            <>
                              <div
                                className="flex items-center justify-center cursor-pointer fs-13 bg-primary text-white break-words h-9 rounded-lg px-2"
                                onClick={() => {
                                  acceptCDDRequest(connection);
                                }}
                              >
                                Accept to share your CDD Report
                              </div>
                            </>
                          )}
                        {/* ACCEPT FOR RECEIVER */}
                        {profile._id == connection?.receiver?._id &&
                          connection?.sender_req_cdd &&
                          connection?.sender_req_cdd?.status == "pending" && (
                            <>
                              <div
                                className="flex items-center justify-center cursor-pointer fs-13 bg-primary text-white break-words h-9 rounded-lg px-2"
                                onClick={() => {
                                  acceptCDDRequest(connection);
                                }}
                              >
                                Accept to share your CDD Report
                              </div>
                            </>
                          )}
                        {/* decline FOR SENDER */}
                        {profile?._id == connection?.sender?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "pending" && (
                            <>
                              <div
                                className="flex items-center justify-center cursor-pointer fs-13 border break-words px-2 h-9 rounded-lg border-danger text-danger"
                                onClick={() => {
                                  declineCDDRequest(connection);
                                }}
                              >
                                Decline to share your CDD Report
                              </div>
                            </>
                          )}
                        {/* decline FOR RECEIVER */}
                        {profile._id == connection?.receiver?._id &&
                          connection?.sender_req_cdd &&
                          connection?.sender_req_cdd?.status == "pending" && (
                            <>
                              <div
                                className="flex items-center justify-center cursor-pointer fs-13 border break-words px-2  h-9 rounded-lg border-danger text-danger"
                                onClick={() => {
                                  declineCDDRequest(connection);
                                }}
                              >
                                Decline to share your CDD Report
                              </div>
                            </>
                          )}
                        {/* Decline to share your CDD button  */}
                        {/* {(profile?._id == connection?.sender?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "pending") ||
                          (profile?._id == connection?.receiver?._id &&
                            connection?.sender_req_cdd &&
                            connection?.sender_req_cdd?.status == "pending" && (
                              <>
                                <div
                                  className="flex items-center justify-center cursor-pointer border border-[#86c1f4] whitespace-nowrap text-primary w-full xl:px-2  h-9  rounded-lg"
                                  onClick={() => {
                                    declineCDDRequest(connection);
                                  }}
                                >
                                  Decline to share your CDD
                                </div>
                              </>
                            ))} */}

                        {/* CDD request pending button  */}
                        {profile?._id == connection?.receiver?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "pending" && (
                            <div className="flex items-center justify-center cursor-pointer fs-13 bg-gray-300 hover:cursor-not-allowed text-primary px-2 h-9 rounded-lg">
                              CDD Request Pending
                            </div>
                          )}
                        {profile?._id == connection?.sender?._id &&
                          connection?.sender_req_cdd &&
                          connection?.sender_req_cdd?.status == "pending" && (
                            <div className="flex items-center justify-center cursor-pointer fs-13 bg-gray-300 hover:cursor-not-allowed text-primary px-2  h-9  rounded-lg">
                              CDD Request Pending
                            </div>
                          )}
                      </div>

                      <div className="flex flex-col order-1 md:order-1 xl:order-2 justify-center items-start ms:items-end gap-3 mob:items-center cstBtnWidth">
                        {/* artpass ID button  */}
                        <div
                          className="flex items-center justify-center cursor-pointer !w-36 fs-13 border border-[#86c1f4] text-primary px-2 h-9 rounded-lg"
                          onClick={() => {
                            viewArtpassID(connector);
                          }}
                        >
                          iPass ID
                        </div>
                        {/* Request CDD Report for sender */}
                        {profile._id === connection.sender._id &&
                          !connection.sender_req_cdd &&
                          !connection.receiver.is_sharing_cdd && (
                            <div
                              className="flex items-center justify-center cursor-pointer fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg"
                              onClick={() => {
                                requestCDDReport(connection);
                              }}
                            >
                              Request CDD Report
                            </div>
                          )}
                        {/* <div
                            className="flex items-center justify-center cursor-pointer border border-[#86c1f4] text-primary px-2  h-9 w-full  rounded-lg"
                            onClick={() => {
                              increaseusage(connection);
                            }}
                          >
    incre                      </div> */}
                        {/* Request CDD Report for reciever */}

                        {profile._id === connection.receiver._id &&
                          !connection.receiver_req_cdd &&
                          !connection.sender.is_sharing_cdd && (
                            <div
                              className="flex items-center justify-center cursor-pointer fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg"
                              onClick={() => {
                                requestCDDReport(connection);
                              }}
                            >
                              Request CDD Report
                            </div>
                          )}
                        {/* <div className="fixedwidthbtnWrap" ng-show="(currentUser._id === conn.sender._id) && !conn.sender_req_cdd && !conn.receiver.is_sharing_cdd">
                                        <input
                                            id="btn-request-cf-cdd-{{$index}}"
                                            type="button"
                                            value="Request CDD Report"
                                            style="width: 100%;"
                                            className="butn butn-outline-primary"
                                            ng-click="requestCDDrp(conn.req_id, $index)"
                                        />
                                    </div> */}
                        {/* {connector?.is_sharing_cdd && (   cdd only */}
                        {connector?.is_sharing_cdd &&
                          connection?.sender_req_cdd?.status != "pending" &&
                          connection?.sender_req_cdd?.status != "accepted" && ( // cdd to req check
                            <>
                              {/* <br /> */}
                              <div
                                className="flex items-center justify-center cursor-pointer fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg"
                                // onClick={() => {
                                //   viewCDDReport(connector);
                                // }}
                                onClick={() => {
                                  requestCDDReport(connection);
                                }}
                              >
                                {/* CDD Report */}
                                Request CDD Report
                              </div>
                              {/* <div
                                className="flex items-center justify-center cursor-pointer bg-primary w-full  text-white px-2  h-9  rounded-lg"
                                onClick={() => {
                                  viewInspectionReport(connector);
                                }}
                              >
                                Inspection Report
                              </div> */}
                            </>
                          )}

                        {/* CDD Report &   Inspection Report button for sender  */}
                        {profile?._id == connection?.sender?._id &&
                          connection?.sender_req_cdd &&
                          connection?.sender_req_cdd?.status == "accepted" &&
                          accessPII && (
                            <>
                              <div
                                className="flex items-center justify-center cursor-pointer !w-36 fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg
                                "
                                onClick={() => {
                                  viewRequestedCDDReport(
                                    connection?.receiver?.cdd?.fileP
                                  );
                                }}
                              >
                                CDD Report
                              </div>
                              {/* <div
                                    className="flex items-center justify-center cursor-pointer !w-36 fs-13 bg-primary text-white px-2  h-9  rounded-lg"
                                    onClick={() => {
                                      viewRequestedInspectionReport(connector);
                                    }}
                                  >
                                    Inspection Report
                                  </div> */}
                              {connector?.update_available ? (
                                <div
                                  className="flex items-center justify-center cursor-pointer fs-13 bg-[#ffc100] text-white px-2 h-9 !w-36 rounded-lg"
                                  onClick={() =>
                                    handleMergeClick(connector, connection)
                                  }
                                >
                                  Inspection Report
                                </div>
                              ) : (
                                <div
                                  className="flex items-center justify-center cursor-pointer !w-36 fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                  onClick={() =>
                                    handleMergeClick(connector, connection)
                                  }
                                >
                                  Inspection Report
                                </div>
                              )}
                            </>
                          )}

                        {/* CDD Report &   Inspection Report button for receiver */}
                        {profile?._id == connection?.receiver?._id &&
                          connection?.receiver_req_cdd &&
                          connection?.receiver_req_cdd?.status == "accepted" &&
                          accessPII && (
                            <>
                              <div
                                className="flex items-center justify-center cursor-pointer !w-36 fs-13 border border-[#86c1f4] text-primary px-2  h-9 rounded-lg"
                                onClick={() => {
                                  viewRequestedCDDReport(
                                    connection?.sender?.cdd?.fileP
                                  );
                                }}
                              >
                                CDD Report
                              </div>
                              {/* <div
                                    className="flex items-center justify-center cursor-pointer !w-36 fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                    onClick={() => {
                                      // viewRequestedInspectionReport(connector);
                                      increaseusage(connection, connector);
                                    }}
                                  >
                                    Inspection Report
                                  </div> */}
                              {/* <div
                                    className="flex items-center justify-center cursor-pointer !w-36 fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                    onClick={() => handleMergeClick(connector, connection)}
                                  >
                                    Inspection Report
                                  </div> */}
                              {connector?.update_available ? (
                                <div
                                  className="flex items-center justify-center cursor-pointer fs-13 bg-[#ffc100] text-white px-2 h-9 !w-36 rounded-lg"
                                  onClick={() =>
                                    handleMergeClick(connector, connection)
                                  }
                                >
                                  Inspection Report
                                </div>
                              ) : (
                                <div
                                  className="flex items-center justify-center cursor-pointer !w-36 fs-13 bg-primary text-white px-2 h-9 rounded-lg"
                                  onClick={() =>
                                    handleMergeClick(connector, connection)
                                  }
                                >
                                  Inspection Report
                                </div>
                              )}
                            </>
                          )}
                        {/* Request CDD Report button  */}
                        {/* {!connector.is_sharing_cdd &&
                            !(
                              profile?._id == connection?.receiver?._id &&
                              connection?.receiver_req_cdd
                            ) && (
                              <div
                                className="flex items-center justify-center cursor-pointer border border-[#86c1f4] text-primary px-2  h-9 w-full  rounded-lg"
                                onClick={() => {
                                  requestCDDReport(connection);
                                }}
                              >
                                Request CDD Report
                              </div>
                            )} */}
                        {/* { profile._id == "652943c69df2a7301b23c9ab" && <div className="flex items-center justify-center cursor-pointer fs-13 border border-[#ffc100] text-[#ffc100] px-2  h-9 rounded-lg" onClick={() => {activateUpdate(connection);}} >Activate Update</div>} */}
                        {/* { profile._id != "652943c69df2a7301b23c9ab" && connector.update_available && ((profile?._id == connection?.receiver?._id &&
                              connection?.receiver_req_cdd &&
                              connection?.receiver_req_cdd?.status == "accepted") || (profile?._id == connection?.sender?._id &&
                              connection?.sender_req_cdd &&
                              connection?.sender_req_cdd?.status == "accepted")) && <div onClick={() => {downloadUpdate(connection, connector);}} className="flex items-center justify-center cursor-pointer fs-13 bg-[#ffc100] text-white px-2 h-9 !w-36 rounded-lg">Download Update</div>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {filteredNetwork.length !== 0 && (
            <p className="font-bold text-lg pt-2 pb-7 flex justify-center items-center w-full tracking-wide">
              Onboarding Connections
            </p>
          )}
          {(filteredNetwork || [])
            .filter((network) => !network?.user_id?.isSelfOnboard ?? false)
            .map((network, i) => (
              <RenderOnboardingConnections
                network={network}
                i={i}
                key={i}
                isSelfOnboard={false}
              />
            ))}
          {filteredNetwork.some(
            (network) => network?.user_id?.isSelfOnboard
          ) && (
            <p className="font-bold text-lg pt-2 pb-7 flex justify-center items-center w-full tracking-wide">
              Self Onboarding Connections
            </p>
          )}
          {(filteredNetwork || [])
            .filter((network) => network?.user_id?.isSelfOnboard ?? false)
            .map((network, i) => (
              <RenderOnboardingConnections
                network={network}
                i={i}
                key={i}
                isSelfOnboard={true}
              />
            ))}
        </>
      )}
      {users.length >= 0 && (
        <>
          {users.length !== 0 && (
            <p className="font-bold text-lg pb-5 pt-3 flex justify-center items-center w-full tracking-wide">
              Unconnected Users
            </p>
          )}
          {(users || []).map((user, i) => {
            return (
              <div className="sm:flex  w-full gap-4 mb-6" key={i}>
                <div className="2xl:w-[12%] xl:w-[15%] md:w-[22%] sm:w-[25%] w-full flex justify-start pl-5 sm:pl-0 ms:justify-center items-center  mb-4 md:mb-0 ">
                  <img
                    className="h-[98px] w-[120px] object-fill"
                    // src={baseUrl + "/" + connector?.avatar}
                    src={baseUrl + "/" + user?.avatar}
                    alt=""
                  />
                </div>

                <div className="2xl:w-[88%] xl:w-[85%] md:w-[78%] sm:w-[75%] w-full bg-[#f0f6fd] rounded-md p-5 tab_design">
                  <div className="ms:px-2 flex flex-col justify-center items-start w-full sm:!w-[600px]">
                    <div className="mb-4 text-[15px] w-full sm:w-auto">
                      <p className="font-semibold">{user?.company?.name}</p>
                      <p
                        className={`${"text-primary underline cursor-pointer"}`}
                      >
                        {user?.fname + " " + user?.lname}
                      </p>
                      <p className="font-semibold">{user?.username} </p>
                    </div>
                  </div>
                  <div
                    className={
                      "grid grid-cols-1 xl:grid-cols-2 text-[14px] lg:text-[15px] w-full justify-center xl:justify-end items-start gap-2 xl:gap-3"
                    }
                  >
                    <div
                      className="flex items-center justify-center cursor-pointer fs-13 bg-primary text-white break-words h-9 rounded-lg px-2"
                      onClick={() => {
                        connectInvisibly(user);
                      }}
                    >
                      Connect Invisibly
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default ConnectionsRequests;
