import React from "react";
import { useUserContext } from "../../../../contexts/userContext";
import { RxCalendar } from "react-icons/rx";
import { MdBlockFlipped } from "react-icons/md";
import { IoCheckboxOutline } from "react-icons/io5";
import { FiAlertTriangle } from "react-icons/fi";
import { RightOutlined } from "@ant-design/icons";
import { checkName } from "../../Constants/CheckName.constant";
import { message } from "antd";
import { RoleManage } from "../../../../App";
import { checkEmailReminder } from "../../../../services/member";

const items = [
  {
    icon: <RxCalendar className="!h-5 !w-5" />,
    label: "Due",
    button: {
      bg: "#E8F6FD",
      border: "#2C6ED2",
      textColor: "#2C6ED2",
    },
    accessType: ["Due"],
  },
  {
    icon: <MdBlockFlipped className="!h-5 !w-5" />,
    label: "Failing",
    button: {
      bg: "#FDF1F0",
      border: "#ff0000b9",
      textColor: "#FF0000",
    },
    accessType: ["Failing"],
  },
  {
    icon: <IoCheckboxOutline className="!h-5 !w-5" />,
    label: "Passing",
    button: {
      bg: "#e7ffd36b",
      border: "#8acd56",
      textColor: "#4D981B",
    },
    accessType: ["Passing"],
  },
  {
    icon: <FiAlertTriangle className="!h-5 !w-5" />,
    label: "Critical",
    button: {
      bg: "#f5d7401a",
      border: "#F5D840",
      textColor: "#dac249",
    },
    accessType: ["Critical"],
  },
];

const CheckSection = ({ checkData, singleTeamMembers, checkActions }) => {
  const { user } = useUserContext();

  const sendCheckEmailReminder = () => {
    const payload = {
      userId: singleTeamMembers?.userId,
    };
    checkEmailReminder(payload).then((res) => {
      if (res?.error) {
        message.error("No failing checks found, please refresh the page");
      } else {
        message.success("Reminder mail sent successfully");
      }
    });
  };

  return (
    <>
      <div className="flex flex-col justify-between gap-5 w-full pb-3">
        <div className="flex flex-col gap-4">
          {checkData?.map(
            (item, index) =>
              ((item?.status !== "Passing" &&
                singleTeamMembers?.accountType?.type === "MLRO") ||
                singleTeamMembers?.accountType?.type !== "MLRO") && (
                <div
                  onClick={() => {
                    if (
                      item?.status !== "Passing" &&
                      user === item?.checkDetails?.type
                    ) {
                      checkActions?.(item);
                    }
                  }}
                  key={index}
                  className="bg-[#fff] p-4 flex justify-between items-center gap-2 w-full rounded-xl cursor-pointer shadow-md"
                >
                  <div className="flex gap-2 justify-between items-center">
                    {items.map((stats, index) =>
                      stats.accessType.includes(item?.status) ? (
                        <div
                          key={index}
                          style={{
                            color: `${stats.button.textColor}`,
                            borderColor: `${stats.button.border}`,
                            backgroundColor: `${stats.button.bg}`,
                            minWidth: "108px",
                          }}
                          className={`w-[108px] text-base font-bold px-2 py-1 border rounded-md flex gap-3 statss-center items-center`}
                        >
                          <span className="text-xl">{stats.icon}</span>
                          <span>{stats.label}</span>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                    <div className="text-[#000] font-bold text-base flex-1">
                      {checkName[item?.checkDetails?.checkType]}
                    </div>
                  </div>
                  {user === RoleManage?.MLRO &&
                    (item?.checkDetails?.checkType === "read_risk_policy_tm" ||
                      item?.checkDetails?.checkType === "read_risk_asses_tm" ||
                      item?.checkDetails?.checkType ===
                        "tm_ensures_training") &&
                    item?.status !== "Passing" && (
                      <button
                        onClick={() => sendCheckEmailReminder()}
                        className="text-[#2C6ED2] font-bold text-sm right-0"
                      >
                        Remind
                      </button>
                    )}
                  {item?.status !== "Passing" &&
                    user === item?.checkDetails?.type &&
                    item?.checkDetails?.checkType !==
                      "confirm_train_material" &&
                    item?.checkDetails?.checkType !==
                      "upload_train_material" && <RightOutlined />}
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default CheckSection;
