/* eslint-disable jsx-a11y/anchor-is-valid */
import { CheckOutlined } from "@ant-design/icons";
import "../css/newLogin.css";
import AccessRequest from "./AccessRequest";
import { message } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl, getProfile } from "../services/services.profile";

const Pricing = () => {
  const [accessRequest, setAccessRequest] = useState(false);

  const [config, setConfig] = useState({});
  const [alreadyselectprice, Setalreadyselectprice] = useState(null);
  // const [selectprice, Setselectprice] = useState();
  const navigate = useNavigate();
  //getconfig
  useEffect(() => {
    const getConfig = async () => {
      const config = await axios.get(baseUrl + "/payment/getconfig");
      setConfig(config.data);
    };
    getConfig();
  }, []);

  const handleSelect = async (priceId) => {
    await axios
      .post(baseUrl + "/api/sub/sla", {
        priceId: priceId,
        customerId: config.userInfo?.customerId,
        company: config.userInfo?.company,
      })
      .then((res) => {
        localStorage.setItem("mem_page", "ela-sign");
        navigate("/user/membership");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile()
      .then(async (res) => {
        if (res.utype == "PATRON") {
          message.error("Cannot access an area reserved for JUSTE members.");
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <AccessRequest
        accessRequest={accessRequest}
        setAccessRequest={setAccessRequest}
        navigateToRequestSubmitted={false}
      />
      <div className="login-page !bg-white">
        <div className="container">
          <div className="grid grid-cols-12 gap-6 justify-center !py-[60px]">
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
              <div className="mil-price-card !bg-transparent mb-[30px] h-full">
                <h5 className="mb-[15px] mt-0">Starter</h5>
                <p className="text-base mb-5 !text-[#7f7f7f]">
                  Best for smaller teams who want to simplify KYB/KYC
                  administration
                </p>
                <h3 className="mb-[30px]">
                  <div className="">
                    € <span data-year-price={499}>499</span>
                    <span className="mil-sup-text !text-[#7f7f7f] !font-normal">
                      / year
                    </span>
                  </div>
                  <p className="text-sm !text-[#7f7f7f]">
                    € 41 /equivalent per month
                  </p>
                </h3>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelect("KYB_PRO_25");
                  }}
                  className="mil-btn mil-m mil-fw mb-[30px]"
                >
                  Select
                </a>

                <div className="flex flex-col gap-2 !text-sm">
                  <div className="flex gap-2 items-center justify-center">
                    <CheckOutlined className="play-icon-gray" />
                    <div className="!text-[#7f7f7f] !font-semibold !">
                      25 Inspection reports per year
                    </div>
                  </div>
                  <div className="flex gap-2 items-center justify-center">
                    <CheckOutlined className="play-icon-gray" />
                    <p className="!text-sm !">
                      <span className="!text-[#7f7f7f]">Includes</span>
                      <span className="!text-[#7f8694] ml-1 !font-semibold">
                        Risk assessment reports
                      </span>
                    </p>
                  </div>

                  {[
                    "Customer management dashboard",
                    "3 team members",
                    "Cross-reference risk policy integration",
                    "Automated branded onboarding link",
                    "Customer onboarding support chat",
                    "24/7 ongoing customer monitoring",
                    "Email support",
                    "Free 30 minutes demo",
                  ].map((feature, index) => (
                    <div
                      key={index}
                      className="flex gap-2 items-center justify-center"
                    >
                      <CheckOutlined className="play-icon-gray" />
                      <p className="!text-sm !text-[#7f7f7f]">{feature}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="relative col-span-12 sm:col-span-6 lg:col-span-4 h-full">
              <div className="absolute -top-5 left-1/2 -translate-x-1/2 text-base px-5 py-2 bg-[#E7F21F] text-[#1a3732] flex items-center justify-center rounded-lg  font-semibold">
                Most Popular
              </div>
              <div className="mil-price-card mil-featured mb-[30px] h-full">
                <h5 className="mil-light mb-[15px] mt-0 opacity-95">Pro</h5>
                <p className="text-base !text-white opacity-90 mb-5">
                  Ideal for professionals in law, real estate, accounting, and
                  art/luxury markets.
                </p>
                <h3 className="mil-light mb-[30px]">
                  <div className="!text-white">
                    €{" "}
                    <span
                      className="mil-light mil-pricing-table-price !text-white !opacity-100"
                      data-year-price="1,149"
                    >
                      1,149
                    </span>
                    <span className="mil-sup-text !text-white opacity-90 !font-normal">
                      / year
                    </span>
                  </div>
                  <p className="text-sm !text-white opacity-90">
                    € 95 /equivalent per month
                  </p>
                </h3>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelect("KYB_PRO_75");
                  }}
                  className="cursor-pointer mb-[30px] h-14 text-base px-[30px] bg-[#E7F21F] text-[#1a3732] flex items-center justify-center rounded-lg  font-semibold hover:bg-[#e6ee59] transition duration-300"
                >
                  Select
                </div>
                <div className="flex flex-col gap-12">
                  <div className="flex gap-2 items-center justify-center">
                    <CheckOutlined className="play-icon-w" />
                    <div className="!text-sm !">
                      <span className="!text-white opacity-80">
                        All the features of
                      </span>
                      <span className="ml-1 !font-semibold !text-white !opacity-100">
                        Starter
                      </span>
                      <span className="!text-white opacity-80">, and:</span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 !text-sm">
                    <div className="flex gap-2 items-center justify-center">
                      <CheckOutlined className="play-icon-w" />
                      <div className="!text-white !font-semibold !">
                        75 Inspection reports per year
                      </div>
                    </div>
                    <div className="flex gap-2 items-center justify-center">
                      <CheckOutlined className="play-icon-w" />
                      <div className="!text-white opacity-80 ">
                        5 team members
                      </div>
                    </div>
                    <div className="flex gap-2 items-center justify-center">
                      <CheckOutlined className="play-icon-w" />
                      <div className="!text-white opacity-80 ">
                        Priority email support
                      </div>
                    </div>
                    <div className="flex gap-2 items-center justify-center">
                      <CheckOutlined className="play-icon-w" />
                      <div className="!text-white opacity-80 ">API access</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 h-full">
              <div className="mil-price-card !bg-transparent mb-[30px] h-full">
                <h5 className=" mb-[15px] mt-0">Enterprise</h5>
                <p className="text-base mb-[30px] !text-[#7f7f7f]">
                  Volume-based for banks, fintechs, neobanks, and crypto firms.
                </p>

                <h4 className="mb-[30px] pb-3">
                  Contact sales
                  <p className="text-sm h-6" />
                </h4>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    setAccessRequest(true);
                  }}
                  className="mil-btn mil-m mil-fw mb-5"
                >
                  Contact sales
                </a>
                <div className="flex flex-col gap-12 !text-sm">
                  <div className="flex gap-2 items-center justify-center">
                    <CheckOutlined className="play-icon-gray" />

                    <p className=" !text-sm !">
                      <span className="!text-[#7f7f7f]">
                        All the features of
                      </span>
                      <span className="!text-[#7f8694] ml-1 !font-semibold">
                        Pro
                      </span>
                      <span className="!text-[#848994]">, and:</span>
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    {[
                      "Unlimited team members",
                      "Dedicated technical consultant",
                      "Enterprise Customer Success Manager",
                    ].map((feature, index) => (
                      <div
                        key={index}
                        className="flex gap-2 items-center justify-center"
                      >
                        <CheckOutlined className="play-icon-gray" />
                        <p className="!text-sm !text-[#7f7f7f]">{feature}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;

// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-useless-escape */
// /* eslint-disable eqeqeq */
// import { Button, Card, Col, List, Row, Typography, message } from "antd";
// import { CgInfinity } from "react-icons/cg";
// import { RiCheckFill, RiCloseFill } from "react-icons/ri";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import { useLocation } from "react-router";
// import { useNavigate } from "react-router-dom";
// import { baseUrl, getProfile } from "../services/services.profile";
// import { current } from "@reduxjs/toolkit";

// var plans = [
//   {
//     title: "COMPLIANCE_5",
//     price: "£ 95",
//     priceId: "COMPLIANCE_5",
//     list: [
//       { title: "Inspection Reports", icon: "5" },
//       { title: "Transaction Reports", icon: "Included" },
//       { title: "CDD Reports", icon: "Unlimited" },
//       {
//         title: "Ongoing Monitoring",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       {
//         title: "Branded Onboarding Link",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
//     ],
//   },
//   {
//     title: "COMPLIANCE_25",
//     price: "£ 295",
//     priceId: "COMPLIANCE_25",
//     list: [
//       { title: "Inspection Reports", icon: "25" },
//       { title: "Transaction Reports", icon: "Included" },
//       { title: "CDD Reports", icon: "Unlimited" },
//       {
//         title: "Ongoing Monitoring",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       {
//         title: "Branded Onboarding Link",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       { title: "Premium API", icon: <RiCloseFill size={22} color={"red"} /> },
//     ],
//   },
//   {
//     title: "COMPLIANCE_50",
//     price: "£ 550",
//     priceId: "COMPLIANCE_50",
//     list: [
//       { title: "Inspection Reports", icon: "50" },
//       { title: "Transaction Reports", icon: "Included" },
//       { title: "CDD Reports", icon: "Unlimited" },
//       {
//         title: "Ongoing Monitoring",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       {
//         title: "Branded Onboarding Link",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       { title: "Premium API", icon: <RiCloseFill size={22} color={"red"} /> },
//     ],
//   },
//   {
//     title: "COMPLIANCE_100",
//     price: "£ 995",
//     priceId: "COMPLIANCE_100",
//     list: [
//       { title: "Inspection Reports", icon: "100" },
//       { title: "Transaction Reports", icon: "Included" },
//       { title: "CDD Reports", icon: "Unlimited" },
//       {
//         title: "Ongoing Monitoring",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       {
//         title: "Branded Onboarding Link",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       { title: "Premium API", icon: <RiCloseFill size={22} color={"red"} /> },
//     ],
//   },
//   {
//     title: "COMPLIANCE_250",
//     price: "£ 2,375",
//     priceId: "COMPLIANCE_250",
//     list: [
//       { title: "Inspection Reports", icon: "250" },
//       { title: "Transaction Reports", icon: "Included" },
//       { title: "CDD Reports", icon: "Unlimited" },
//       {
//         title: "Ongoing Monitoring",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       {
//         title: "Branded Onboarding Link",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
//     ],
//   },
//   {
//     title: "COMPLIANCE_500",
//     price: "£ 4,500",
//     priceId: "COMPLIANCE_500",
//     list: [
//       { title: "Inspection Reports", icon: "500" },
//       { title: "Transaction Reports", icon: "Included" },
//       { title: "CDD Reports", icon: "Unlimited" },
//       {
//         title: "Ongoing Monitoring",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       {
//         title: "Branded Onboarding Link",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
//     ],
//   },
//   {
//     title: "COMPLIANCE_750",
//     price: "£ 6,375",
//     priceId: "COMPLIANCE_750",
//     list: [
//       { title: "Inspection Reports", icon: "750" },
//       { title: "Transaction Reports", icon: "Included" },
//       { title: "CDD Reports", icon: "Unlimited" },
//       {
//         title: "Ongoing Monitoring",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       {
//         title: "Branded Onboarding Link",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
//     ],
//   },
//   {
//     title: "COMPLIANCE_1000",
//     price: "£ 7,950",
//     priceId: "COMPLIANCE_1000",
//     list: [
//       { title: "Inspection Reports", icon: "1000" },
//       { title: "Transaction Reports", icon: "Included" },
//       { title: "CDD Reports", icon: "Unlimited" },
//       {
//         title: "Ongoing Monitoring",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       {
//         title: "Branded Onboarding Link",
//         icon: <RiCheckFill size={22} color={"green"} />,
//       },
//       { title: "Premium API", icon: <RiCheckFill size={22} color={"green"} /> },
//     ],
//   },
// ];

// function Pricing() {
//   const [config, setConfig] = useState({});
//   const [alreadyselectprice, Setalreadyselectprice] = useState(null);
//   // const [selectprice, Setselectprice] = useState();
//   const navigate = useNavigate();
//   //getconfig
//   useEffect(() => {
//     const getConfig = async () => {
//       const config = await axios.get(baseUrl + "/payment/getconfig");
//       setConfig(config.data);
//     };
//     getConfig();
//   }, []);

//   const handleSelect = async (priceId) => {
//     let obj = plans.find((o) => o.priceId === priceId);
//     let oldprice = "";
//     let currentres = {};

//     getProfile()
//       .then(async (res) => {
//         if (res.membership) {
//           console.log(
//             res.membership.name.replace(" ", "_").toUpperCase(),
//             "res"
//           );
//           currentres = res;
//           // setTimeout(()=>{
//           //  await setCurrentInfo(res.sla_sign?.sub_info?.priceId)
//           // console.log(currentinfo,"INFO")
//           plans.forEach((x) => {
//             const memberShipPrice = res.membership.name
//               .replace(" ", "_")
//               .toUpperCase();
//             if (x.priceId === memberShipPrice) {
//               Setalreadyselectprice(x.price);
//               oldprice = x.price;
//             }
//           });
//           // },2000)
//         }
//       })
//       .then(async (re) => {
//         // if(Number(oldprice.replace(/[^0-9\.-]+/g,"")) ==Number(obj.price.replace(/[^0-9\.-]+/g,"")) || priceId =='COMPLIANCE_5'){
//         // if (
//         //   Number(oldprice.replace(/[^0-9\.-]+/g, "")) >
//         //     Number(obj.price.replace(/[^0-9\.-]+/g, "")) &&
//         //   currentres.subscription
//         // )
//         if (
//           Number(oldprice.replace(/[^0-9\.-]+/g, "")) ==
//             Number(obj.price.replace(/[^0-9\.-]+/g, "")) &&
//           false
//         ) {
//           // localStorage.setItem("mem_page", "details");
//           // navigate("/user/membership");
//           message.error("Please select upgrade subscription plan");
//         } else {
//           await axios
//             .post(baseUrl + "/api/sub/sla", {
//               priceId: priceId,
//               customerId: config.userInfo?.customerId,
//               company: config.userInfo?.company,
//             })
//             .then((res) => {
//               if (currentres.subscription) {
//                 // if(Number(oldprice.replace(/[^0-9\.-]+/g,"")) >Number(obj.price.replace(/[^0-9\.-]+/g,"")) || priceId =='COMPLIANCE_5'){
//                 //   // alert("no Downgrade")
//                 //   message.error("Downgrade is not possible");

//                 // }else
//                 if (
//                   Number(oldprice.replace(/[^0-9\.-]+/g, "")) ==
//                   Number(obj.price.replace(/[^0-9\.-]+/g, ""))
//                 ) {
//                   localStorage.setItem("mem_page", "details");
//                   navigate("/user/membership");
//                 } else if (
//                   Number(oldprice.replace(/[^0-9\.-]+/g, "")) <
//                   Number(obj.price.replace(/[^0-9\.-]+/g, ""))
//                 ) {
//                   localStorage.setItem("mem_page", "ela-sign");
//                   navigate("/user/membership");
//                 }
//               } else {
//                 localStorage.setItem("mem_page", "ela-sign");
//                 navigate("/user/membership");
//               }
//             })
//             .catch((err) => {
//               console.log(err);
//             });
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     getProfile()
//       .then(async (res) => {
//         if (res.utype == "PATRON") {
//           message.error("Cannot access an area reserved for JUSTE members.");
//           navigate("/");
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, []);

//   return (
//     <>
//       <Row gutter={[24, 48]}>
//         {plans.map((plan, index) => (
//           <Col
//             key={index}
//             xs={20}
//             sm={12}
//             md={10}
//             lg={8}
//             xl={6}
//             // span={6}
//           >
//             <Card
//               className="bg-light"
//               bodyStyle={{ padding: "0px" }}
//               title={plan.title}
//               key={index}
//               actions={[
//                 <Button type="text">{plan.price}</Button>,
//                 <Button
//                   onClick={() => {
//                     handleSelect(plan.priceId);
//                   }}
//                   type="primary"
//                 >
//                   SELECT
//                 </Button>,
//               ]}
//             >
//               <List
//                 key={index}
//                 size="small"
//                 itemLayout="horizontal"
//                 dataSource={plan.list}
//                 renderItem={(item) => (
//                   <List.Item actions={[item.icon]}>
//                     <List.Item.Meta title={item.title} />
//                   </List.Item>
//                 )}
//               />
//               {plan.text && (
//                 <Typography.Paragraph
//                   style={{
//                     padding: "5px",
//                     fontSize: "14px",
//                     textAlign: "center",
//                     minHeight: "100px",
//                   }}
//                 >
//                   {plan.text}
//                 </Typography.Paragraph>
//               )}
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </>
//   );
// }

// export default Pricing;
