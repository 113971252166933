import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/login.css";
import "../css/default.css";
import bgPersonal from "../assets/images/bg-personal.jpg";
import personalMain from "../assets/images/personal_main.jpg";
import eu from "../assets/images/eu.png";
import ccpa from "../assets/images/ccpa.png";
import gdpr from "../assets/images/GDPR.png";
import uk from "../assets/images/uk.png";
import soc2 from "../assets/images/soc2.png";
import iso from "../assets/images/iso.png";
import sha256 from "../assets/images/sha256.png";
import VideoModal from "../components/modal/VideoModal";
import AOS from "aos";
import "aos/dist/aos.css";
import { AiOutlineUser } from "react-icons/ai";
import Signin from "../pages/Signin";
import { baseUrl } from "../services/services.profile";

const Personal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isHeaderPersonal, setIsHeaderPersonal] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const changeNavbar = () => {
    if (window.scrollY >= 20) {
      setIsHeaderPersonal(true);
    } else {
      setIsHeaderPersonal(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);

  return (
    <>
      <Signin
        modal={modal}
        setModal={setModal}
        isClose={() => setModal(false)}
      />
      <VideoModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <div>
        <div>
          <span
            id="menu-remove"
            className={`fixed top-0 left-0 z-20 ${
              toggle ? "block" : "hidden"
            }  h-screen w-full bg-[rgba(000,0,0,0.5)]`}
            onClick={() => {
              setToggle(!toggle);
            }}
          ></span>
        </div>
        <header id="header">
          <nav
            className={`${
              isHeaderPersonal
                ? "navbar navbar-expand navbar-fixed sub items-center flex justify-center visible navbar-sticky"
                : "navbar navbar-expand navbar-fixed sub items-center flex justify-center"
            }`}
          >
            <div className="container header flex px-[25px] py-5 justify-between items-center">
              <Link className="navbar-brand whitespace-nowrap" to="/">
                <span className="brand">JUSTE</span>
              </Link>

              <ul className="flex justify-center items-center lg:gap-8 gap-3 text-white list-none">
                <li className="p-2 hidden lg:block">
                  <Link
                    to="/personal"
                    className="!text-white hover:!text-[#507025]"
                  >
                    Personal
                  </Link>
                </li>
                <li className="p-2 hidden lg:block">
                  <Link
                    to="/business"
                    className="!text-white hover:!text-[#507025]"
                  >
                    Business
                  </Link>
                </li>
                <li className="p-2">
                  <a
                    onClick={() => setModal(true)}
                    className="!text-white flex justify-center items-center hover:!text-[#507025]"
                    data-toggle="modal"
                    data-target="#artpass-signin"
                  >
                    <AiOutlineUser className="h-6 w-7" />
                  </a>
                </li>
                <li className="p-2 hidden lg:block">
                  <a
                    href="https://onboarding.kyb.pro"
                    target="_blank"
                    className="border px-6 py-[10px] h-[40px] border-solid border-white hover:border-none hover:bg-[#507025] !text-white"
                  >
                    Join us now!
                  </a>
                </li>
                <button
                  data-collapse-toggle="divbar-sticky"
                  type="button"
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                  id="menu-icon"
                  className="flex items-center w-9 h-9 min-w-[36px] justify-center text-sm text-white rounded-full lg:hidden"
                  aria-controls="navbar-sticky"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </ul>
            </div>
          </nav>
        </header>

        <div
          className={`fixed top-0 right-0 left-0 z-50 sm:w-3/4  ${
            !toggle ? "-translate-x-full" : ""
          } h-[50%] w-full max-w-[20rem]  bg-white shadow-lg transition duration-500`}
          id="menu-items"
        >
          <ul className="list-none p-6 ">
            <li className="py-2 px-4">
              <a href="/kybpro/" className="!font-bold !text-lg">
                Home
              </a>
            </li>
            <li className="py-2 px-4">
              <a
                href="https://onboarding.kyb.pro"
                target="_blank"
                className="!font-bold !text-lg"
              >
                Join us
              </a>
            </li>
            <li className="py-2 px-4">
              <a
                href="#"
                data-toggle="modal"
                data-target="#artpass-signin"
                className="!font-bold !text-lg"
              >
                Sign in
              </a>
            </li>
            <li className="py-2 px-4">
              <a href="/kybpro/business" className="!font-bold !text-lg">
                Business
              </a>
            </li>
            <li className="py-2 px-4">
              <a href="/kybpro/personal" className="!font-bold !text-lg">
                Personal
              </a>
            </li>
          </ul>
        </div>

        {/* section 1  */}
        <section
          id="slider"
          className="bg-[#080d10] p-0 text-[#ffffff] relative"
        >
          <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
            <div className="swiper-wrapper">
              <div className="swiper-slide slide-center">
                <img
                  src={bgPersonal}
                  alt="Full Image"
                  className="full-image"
                  data-mask="80"
                />
              </div>
            </div>
          </div>
        </section>

        <div className="flex flex-col  text-center">
          {/* section 2 */}
          <section className="bg-black flex justify-center items-center md:py-9 py-20">
            <div className="text-white font-medium text-2xl">
              Enjoy instant trust with verified KYC{" "}
            </div>
          </section>

          {/* section 3 */}
          <section
            id="skills"
            className="bg-[#000000f2] relative grid md:grid-cols-2 grid-cols-1  justify-center items-start"
          >
            <div className="pl-4 pr-12 py-9">
              <h2 className="!text-white !text-[32px] lg:!text-[54px] text-start px-4">
                The 1-Click KYC App. What are the Advantages?
              </h2>
              <div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="grid grid-cols-4 justify-center items-center flex-col pt-5 pb-2 pl-12"
                >
                  <div className="pr-4">
                    <span className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                      1
                    </span>
                  </div>
                  <div className="col-span-3 flex justify-start items-start flex-col px-4">
                    <h4 className="!text-white font-medium">Fast Onboarding</h4>
                    <p className="!text-white !text-start">
                      It only takes a few minutes to get your JUSTE.{" "}
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="300"
                  className="grid grid-cols-4 justify-center items-center flex-col pt-5 pb-2 pl-12"
                >
                  <div className="pr-4">
                    <span className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center ">
                      2
                    </span>
                  </div>
                  <div className="col-span-3 flex justify-start items-start flex-col px-4">
                    <h4 className="!text-white font-medium">Smart Solution</h4>
                    <p className="!text-white !text-start">
                      JUSTE members can instantly connect with each other and
                      securely share their CDD Report with 1-Click.
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="400"
                  className="grid grid-cols-4 justify-center items-center flex-col pt-5 pb-2 pl-12"
                >
                  <div className="pr-4">
                    <span className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center ">
                      3
                    </span>
                  </div>
                  <div className="col-span-3 flex justify-start items-start flex-col px-4">
                    <h4 className="!text-white font-medium">Secure Sharing</h4>
                    <p className="!text-white !text-start">
                      There is no need for physical passports and utility bills
                      to be taken to places or emailed.
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="500"
                  className="grid grid-cols-4 justify-center items-center flex-col pt-5 pb-2 pl-12"
                >
                  <div className="pr-4">
                    <span className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center ">
                      4
                    </span>
                  </div>
                  <div className="col-span-3 flex justify-start items-start flex-col px-4">
                    <h4 className="!text-white font-medium">Instant Trust</h4>
                    <p className="!text-white !text-start">
                      Transparent and level playing. Everyone on the network –
                      collectors and dealers are KYC cleared.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <img
              alt=""
              src={personalMain}
              className="w-full 2xl:h-[870px] xl:h-[930px] h-full  object-cover"
            />
          </section>

          {/* section 4 */}
          <section id="work" className="section-3 py-[75px] w-full bg-black">
            <h2 className="!text-white mb-[70px] !text-center !text-[32px] lg:!text-[54px] px-4">
              How Does it Work for JUSTE Members?
            </h2>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 pt-4 lg:justify-between justify-center items-start text-center 2xl:mx-[450px] xl:mx-[250px] lg:mx-[220px] mx-auto pb-1">
              <div className="flex justify-center items-center flex-col mb-7 px-4">
                <div className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                  <span className="">1</span>
                </div>
                <h4 className="!text-white font-medium">
                  1-Click <br />
                  Scan & Connect
                </h4>
                <p className="text-white">
                  Any JUSTE member can request a connection by scanning a
                  QR-Code or entering an JUSTE number.
                </p>
              </div>
              <div className="flex justify-center items-center flex-col mb-7 px-4">
                <div className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                  <span className="">2</span>
                </div>
                <h4 className="!text-white font-medium">
                  Verified Connections
                </h4>
                <p className="text-white">
                  Once you onboarded via their private link, you will
                  automatically connect and share your CDD Report with them.
                </p>
              </div>
              <div className="flex justify-center items-center flex-col mb-7 px-4  md:col-span-2 lg:col-span-1">
                <div className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                  <span className="">3</span>
                </div>
                <h4 className="!text-white font-medium">Compliant Reports</h4>
                <p className="text-white md:max-w-[400px] lg:max-w-auto">
                  The moment you grant access to your CDD Report, they will also
                  have access to your Inspection Report.
                </p>
              </div>
            </div>
          </section>

          {/* section 5 */}
          <section id="work" className="section-3 py-[75px] w-full bg-black">
            <h2 className="!text-white mb-[70px] !text-center !mt-0 !text-[32px] lg:!text-[54px] px-4">
              How Does it Work for Non-JUSTE Members?
            </h2>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 pt-4 lg:justify-between justify-center items-start text-center 2xl:mx-[450px] xl:mx-[250px] lg:mx-[220px] mx-auto pb-1">
              <div className="flex justify-center items-center flex-col mb-7 px-4">
                <div className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                  <span className="">1</span>
                </div>
                <h4 className="!text-white font-medium">
                  Guest onboarding link
                </h4>
                <p className="text-white">
                  Privately onboard as a VIP member via a link that you receive
                  from an AMP requesting your KYC information.
                </p>
              </div>
              <div className="flex justify-center items-center flex-col mb-7 px-4">
                <div className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                  <span className="">2</span>
                </div>
                <h4 className="!text-white font-medium">
                  Verified Connections
                </h4>
                <p className="text-white">
                  Once you onboarded via their private link, you will
                  automatically connect and share your CDD Report with them.
                </p>
              </div>
              <div className="flex justify-center items-center flex-col mb-7 px-4  md:col-span-2 lg:col-span-1">
                <div className=" text-white border-2 border-white rounded-full border-solid w-10 h-10 flex justify-center items-center">
                  <span className="">3</span>
                </div>
                <h4 className="!text-white font-medium">Compliant Reports</h4>
                <p className="text-white md:max-w-[400px] lg:max-w-auto">
                  The moment you grant access to your CDD Report, they will also
                  have access to your Inspection Report.
                </p>
              </div>
            </div>
          </section>

          {/* section 6 */}
          <section
            id="process"
            className="section-3 pt-[75px] pb-5 bg-[#000000f2] offers flex justify-center  items-center flex-col w-full"
          >
            <div className="text-center mb-16">
              <h2 className="!text-white !my-0 !mb-6 !text-[32px] lg:!text-[54px] px-5">
                Sign up in minutes and get your FREE JUSTE!
              </h2>
            </div>
            <button className="border px-6 py-[10px] h-11 border-solid border-white hover:border-none hover:bg-[#507025] mb-12 !text-white">
              <a
                className="!text-white"
                href="https://onboarding.kyb.pro"
                target="_blank"
              >
                Join us now
              </a>
            </button>
          </section>

          {/* section 7 */}
          <section
            id="comp"
            className="section-3 bg-black w-full flex justify-center items-center flex-col py-9"
          >
            <p className="!text-white max-w-[800px] text-center mb-[70px] !text-[32px] lg:!text-[54px] px-4">
              JUSTE is ISO 27001:2022 Certified and complies with AML and Data
              Protection Regulations.
            </p>
            <div className="justify-center items-center grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 mb-12 lg:gap-0 gap-2 lg:max-w-[800px] w-full ">
              <div className="flex justify-center items-center">
                <img alt="" className="w-32 h-32 lg:px-4 lg:h-auto" src={eu} />
              </div>

              <div className="flex justify-center items-center">
                <img alt="" className="w-32 h-32 lg:px-4 lg:h-auto" src={uk} />
              </div>
              <div className="flex justify-center items-center">
                <img
                  alt=""
                  className="w-32 h-32 lg:px-4 lg:h-auto"
                  src={sha256}
                />
              </div>
              <div className="flex justify-center items-center">
                <img
                  alt=""
                  className="w-32 h-32 lg:px-4 lg:h-auto"
                  src={gdpr}
                />
              </div>
              <div className="flex justify-center items-center">
                <img
                  alt=""
                  className="w-32 h-32 lg:px-4 lg:h-auto"
                  src={ccpa}
                />
              </div>
              <div className="flex justify-center items-center">
                <img alt="" className="w-32 h-32 lg:px-4 lg:h-auto" src={iso} />
              </div>
            </div>
            <a
              href="/kybpro/compliance"
              target="_blank"
              className="!text-white border px-6 py-[10px] h-11 border-solid border-white hover:border-none hover:bg-[#507025] my-14"
            >
              Compliance & Security info
            </a>
          </section>

          {/* section 8 */}
          <section id="mission" className="bg-[#000000f2] py-9">
            <div className="flex justify-center items-center flex-col text-center gap-5 pb-5 px-4">
              <p className="!text-white !text-[32px] lg:!text-[54px] ">
                A shared mission
              </p>
              <p className="max-w-[800px] !text-white">
                By teaming up with JUSTE, you can help accelerate the transition
                to achieve friction-free AML compliant transactions while
                building a culture of trust and transparency.
              </p>
            </div>
            <button className="border px-6 py-[10px] h-11 border-solid border-white hover:border-none hover:bg-[#507025] my-14  !text-white">
              <a
                className="!text-white"
                href="https://onboarding.kyb.pro"
                target="_blank"
              >
                Join us now
              </a>
            </button>
          </section>

          <footer>
            <section id="copyright" className="p-4 !bg-black">
              <div className="flex justify-center items-center">
                <div className="container">
                  <div className="p-2 text-center text-white text-base">
                    <p>
                      <a
                        href={baseUrl + "/terms/"}
                        target="_blank"
                        className="!text-white font-light"
                      >
                        Terms & Conditions{" "}
                      </a>
                      <a
                        href={baseUrl + "/privacy_policy.html"}
                        target="_blank"
                        className="!text-white font-light"
                      >
                        {" "}
                        | Privacy Policy{" "}
                      </a>
                      <a
                        href={baseUrl + "/cookie_policy.html"}
                        target="_blank"
                        className="!text-white font-light"
                      >
                        {" "}
                        | Cookie Policy{" "}
                      </a>
                      <a
                        href={baseUrl + "/disclaimer.html"}
                        target="_blank"
                        className="!text-white font-light"
                      >
                        {" "}
                        | Disclaimer{" "}
                      </a>
                      <a
                        href={baseUrl + "/#artpass-signin"}
                        target="_blank"
                        className="!text-white font-light"
                      >
                        {" "}
                        | Sign in{" "}
                      </a>
                      <a
                        href="#artpass-signin"
                        data-dismiss="modal"
                        data-toggle="modal"
                        className="!text-white font-light"
                      >
                        | Sign in
                      </a>
                      <a
                        href="https://onboarding.kyb.pro/#/"
                        target="_blank"
                        className="!text-white font-light"
                      >
                        {" "}
                        | Join us{" "}
                      </a>
                    </p>
                  </div>
                  <div className="text-center text-white text-xs">
                    JUSTE Ltd. is registered as a Data Processor with the UK
                    Information Commissioner’s Office under number ZA633666. Our
                    data protection officer can be contacted by email at
                    dpo@juste.ai. © 2021 JUSTE Ltd. All rights reserved. JUSTE,
                    1-Click KYC and 1-Click Compliance are registered trademarks
                    by JUSTE. Company number: 12402898
                  </div>
                </div>
              </div>
            </section>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Personal;
