import React, { useEffect } from "react";
import { InlineWidget } from "react-calendly";
import { useNavigate } from "react-router";

const CALENDLY_EVENT = "calendly.event_scheduled";

const AccessRequest = ({
  accessRequest,
  setAccessRequest,
  navigateToRequestSubmitted = true,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCalendlyEvent = (e) => {
      if (e.data.event && e.data.event === CALENDLY_EVENT) {
        setAccessRequest(false);
        if (navigateToRequestSubmitted) {
          navigate("/request-submitted");
        }
      }
    };

    window.addEventListener("message", handleCalendlyEvent);

    return () => {
      window.removeEventListener("message", handleCalendlyEvent);
    };
  }, [navigate, setAccessRequest]);

  return (
    <>
      {accessRequest && (
        <div className="fixed inset-0 z-[999] overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setAccessRequest(false)}
          ></div>
          <div className="flex flex-col justify-center items-center min-h-screen bg-[#00000073] py-8">
            <div className="relative min-w-[100vw]  sm:min-w-[80vw] lg:min-w-[1000px] h-[700px] bg-white rounded-md shadow-lg p-4">
              <div className="flex items-center justify-center h-full">
                Loading...
              </div>
              <div className="absolute top-0 right-0 w-full h-full">
                <InlineWidget
                  url="https://calendly.com/demo-kyb/30min?hide_gdpr_banner=1"
                  styles={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(AccessRequest);
