export const setLocalStorageData = async (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageData = async (key) => {
  const data = JSON.parse(localStorage.getItem(key));
  return data;
};

export const downloadFileFromUrl = async (fileUrl, fileName) => {
  const response = await fetch(fileUrl);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const filename = fileName;
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
