/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import "../css/newLogin.css";
import about_img from "../assets/images/about_img.jpg";
import { useState } from "react";
import { FaPlay } from "react-icons/fa";
import VideoModal from "../components/modal/VideoModal";
import AOS from "aos";
import "aos/dist/aos.css";
import monitoring from "../assets/images/home-1/monitoring.png";
import { DownOutlined } from "@ant-design/icons";
import AccessRequest from "./AccessRequest";
import Header from "./Header";
import Footer from "./Footer";
import Signin from "./Signin";
import { Outlet, useLocation } from "react-router";

const Login = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [visible, setVisible] = useState(false);
  const [accessRequest, setAccessRequest] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    let timeoutId;

    const toggleVisibility = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setVisible(window.scrollY > 250);
      }, 100);
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (isOpen === true || sideBar === true || accessRequest || modal) {
      document.body.style.overflow = "hidden";
    } else {
      if (document.body.style.overflow === "hidden") {
        document.body.style.overflow = "visible";
      }
    }
  }, [accessRequest, isOpen, modal, sideBar]);

  return (
    <div className="bg-white">
      {/* {header} */}
      <Header
        setSideBar={setSideBar}
        setModal={setModal}
        setAccessRequest={setAccessRequest}
      />

      <Signin
        modal={modal}
        setModal={setModal}
        isClose={() => setModal(false)}
        setAccessRequest={setAccessRequest}
      />

      {/* {AccessRequest && ( */}
      <AccessRequest
        accessRequest={accessRequest}
        setAccessRequest={setAccessRequest}
      />
      {/* )} */}

      <Outlet />

      {location.pathname === "/" && (
        <>
          <VideoModal isOpen={isOpen} setIsOpen={setIsOpen} loop={false} />

          <div className="bg-white">
            <div className="login-page">
              <div className="bg-[#f2fafa] mt-[60px]">
                <div className="container !py-20">
                  {/* banner */}

                  <div className="mb-[100px] flex">
                    <h2 className="inline-block flex-wrap justify-center items-center text-center mb-0 mt-0 lgb:mt-6 !font-bold !text-[65px]">
                      The Fastest Know Your Business (KYB) Verification Tool
                      Ever <br className="xl:flex hidden" />
                      Made
                      <div
                        onClick={() => setIsOpen(true)}
                        className="play-button-banner ml-[24px] cursor-pointer"
                      >
                        <div className="play-icon-banner"></div>
                      </div>
                    </h2>
                  </div>
                  {/* video */}
                  <div className="px-[15px] mb-12">
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="shadow-[0_4px_8px_rgba(0,_0,_0,_0.2)] border border-transparent rounded-md h-auto w-full"
                    >
                      <source
                        src="https://api.kyb.pro/videos/Intro_header_kyb_pro.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  {/* {buttons} */}
                  <div className="flex gap-5 flex-col mb-[100px]">
                    <h5 className="flex flex-col text-center !font-normal !text-[17px] text-[#1a3732]">
                      <span>KYB verifications shouldn’t slow you down.</span>

                      <span className="mt-[4px]">
                        Verify businesses effortlessly and reduce compliance
                        risks.
                      </span>
                    </h5>
                    <div className="cursor-pointer flex justify-center gap-4 ms:gap-6 sm:gap-10 flex-col ms:flex-row mt-1">
                      <div className="bg-[#E7F21F] text-[#1a3732] w-full text-center ms:w-fit px-4 ms:px-7 py-3 ms:py-4 text-[14px] rounded-[10px]  font-normal hover:bg-opacity-80 transition-opacity duration-300">
                        How does it work?
                      </div>
                      <div
                        onClick={() => {
                          setAccessRequest(true);
                          setSideBar(false);
                        }}
                        className="select-none cursor-pointer bg-[#1a3732] !text-white w-full text-center ms:w-fit px-4 ms:px-7 py-3 ms:py-4 text-[14px] rounded-[10px] font-normal hover:bg-opacity-80 transition-opacity duration-300"
                      >
                        Request access
                      </div>
                    </div>
                  </div>
                  {/* {tags} */}
                  {/* <div className="text-center flex gap-8 flex-col">
                    <div className="!text-[15px] !font-light !text-[#7f7f7f]">
                      SOME OF THE AWESOME AML-REGULATED COMPANIES THAT TRUST KYB
                      PRO
                    </div>
                    <div className="flex gap-x-5 md:gap-x-6 flex-wrap !text-[21px] justify-center">
                      {[
                        "EasyFairs",
                        "Almine Rech Gallery",
                        "Spruth Magers Gallery",
                        "Tuerlinckx Tax Laywers",
                        "Mobull Art Packers",
                        "Corinth Consulting",
                        "International Art Finance",
                        "De Vuyst Auctioneers",
                        "Horta Auctioneers",
                      ]?.map((item, index) => (
                        <h5
                          className="whitespace-nowrap my-1 md:my-2 lg:my-3 !text-base !font-semibold"
                          key={index}
                        >
                          {item}
                        </h5>
                      ))}
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="container !pb-[65px] sm:!pb-[100px] mt-[100px]">
                <div className="text-center flex gap-5 flex-col mb-[100px] justify-center items-center">
                  <p className="text-[15px] !font-normal mb-4">OUR PRODUCT</p>
                  <h3 className="m-0 !font-bold !text-[48px]">
                    KYB Pro explained in 3 minutes
                  </h3>
                  <div className="p-4 sm:p-6 md:p-8 bg-[#f2fafa] w-auto rounded-[20px] sm:rounded-[18px] mt-4">
                    <div className="lgs:order-2 relative">
                      <img
                        src={about_img}
                        alt="About"
                        className="lgd:max-w-[700px] rounded-[15px]"
                      />

                      <FaPlay
                        className="play-icon w-[70px] h-[70px] hover:w-20 hover:h-20 absolute bottom-[40%] right-0 left-[50%] cursor-pointer"
                        onClick={() => setIsOpen(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-12 flex-col mb-[100px] justify-center items-center">
                  <p className="!text-[15px] !font-normal">OUR SOLUTION</p>

                  {/* call to action */}

                  <div className="flex justify-center flex-col items-center lgb:rounded-[40px] bg-[#f2fafa] p-16 mil-out-frame mil-p-160-100">
                    <div className="mb-6 lgb:mb-14 flex justify-center flex-col items-center">
                      <h3 className="text-center mt-0 lgb:mb-7 !text-[48px] !text-[#1a3732] !font-bold">
                        KYB AI risk assessment should not be complicated
                      </h3>

                      <div className="text-lg lgb:text-xl leading-6 text-center !text-[17px] !font-light">
                        KYB is one of the hardest parts of Anti-Money Laundering
                        (AML) and Financial Sanctions compliance. We built KYB
                        Pro AI-driven as a solution to solve this problem for
                        ourselves at Juste.ai—after thousands of verifications,
                        we perfected the tool. Now we share it with you.
                      </div>
                    </div>

                    <div className="grid grid-cols-12 gap-8 lgb:gap-14 lgb:mx-6">
                      <div className="lgb:col-span-4 col-span-12">
                        <div className="mil-icon-box">
                          <h5 className="my-5 lgb:mb-[20px] mt-0 !text-[17px] !font-semibold text-center">
                            Fast & transparent KYB
                          </h5>
                          <p className="!text-[13px] !font-light text-center !text-[#1a3732]">
                            Eliminate KYB delays—instantly access control and
                            ownership data with easy-to-use cross-reference
                            analysis and risk level recommendations.
                          </p>
                        </div>
                      </div>
                      <div className="lgb:col-span-4 col-span-12">
                        <div className="mil-icon-box">
                          <h5 className="my-5 lgb:mb-[20px] mt-0 !text-[17px] !font-semibold text-center">
                            Global compliance network
                          </h5>
                          <p className="!text-[13px] !font-light text-center !text-[#1a3732]">
                            Verify businesses globally in more than 170
                            countries—multilingual document checks ensure
                            robust, compliant reports.
                          </p>
                        </div>
                      </div>
                      <div className="lgb:col-span-4 col-span-12">
                        <div className="mil-icon-box">
                          <h5 className="my-5 lgb:mb-[20px] mt-0 !text-[17px] !font-semibold text-center">
                            Risk-based AML compliance
                          </h5>
                          <p className="!text-[13px] !font-light text-center !text-[#1a3732]">
                            Inspection and Risk Assessment Reports provide a
                            documented, risk-based approach to ensure due
                            diligence and avoid compliance fines.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-14 whitespace-nowrap cursor-pointer bg-[#E7F21F] text-[#1a3732] px-8 py-3 text-base rounded-[10px] text-[14px] font-normal hover:bg-opacity-80 transition-opacity duration-300 w-fit flex gap-2 items-center">
                      Learn More
                    </div>
                  </div>
                </div>

                <div className="lgb:grid grid-cols-12 gap-6 lgb:gap-0 justify-center items-center">
                  <div className="col-span-7 mb-[30px] sm:mb-[60px] lgb:mb-0 px-4 sm:px-6 md:px-0 lgb:px-14 xl:px-20">
                    <h3 className="mb-7 lgb:mb-6 mt-0 text-center !text-[48px] !text-[#1a3732] !font-bold">
                      Built for busy AML experts and API integrations
                    </h3>
                    <div className="!mb-[38px] lgb:mb-5 leading-7 !text-[17px] !font-light text-center">
                      Navigate KYB complexity effortlessly with our Al-driven
                      user-friendly tool. No more spreadsheets or outdated
                      compliance tools-KYB Pro is designed for efficiency and
                      ease, so your team can focus on decisions, not data entry.
                    </div>
                    <ul className="mil-list-2 mil-type-2 px-0">
                      <li>
                        <h6 className="mb-4 mt-0 !text-[17px] !font-semibold  text-center">
                          Fast & transparent KYB
                        </h6>
                        <p className="!text-[13px] !font-light text-center !text-[#1a3732]">
                          Eliminate KYB delays-instantly access control and
                          ownership data with easy-to-use cross-reference and
                          risk level screens.
                        </p>
                      </li>
                      <li>
                        <h6 className="mb-4 mt-0 !text-[17px] !font-semibold  text-center">
                          Instant insights
                        </h6>
                        <p className="!text-[13px] !font-light text-center !text-[#1a3732]">
                          Actionable compliance data at your fingertips. View
                          company risk scores, UBO structures, and verification
                          results in one place— helping you make informed
                          compliance decisions in seconds.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-span-5">
                    <div className="h-full flex justify-center">
                      <img
                        src={monitoring}
                        alt="image"
                        className="h-full rounded-mb-10 lgb:rounded-none w-4/5 sm:w-3/4 md:w-auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-[#f2fafa]">
                <div className="container !py-[60px] sm:!py-20 flex flex-col justify-center items-center sm:gap-14 gap-10">
                  <h2 className="text-center my-0 !text-[48px] !text-[#1a3732] !font-bold">
                    The ultimate KYB <br /> automated workflow tool
                  </h2>
                  <div
                    onClick={() => {
                      setAccessRequest(true);
                      setSideBar(false);
                    }}
                    className="select-none cursor-pointer bg-[#E7F21F] text-[#1a3732] ms:w-fit px-6 sm:px-8 py-3 sm:py-4 rounded-[10px]  font-normal hover:bg-opacity-80 transition-opacity duration-300 !text-[17px]"
                  >
                    Request access
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Footer setAccessRequest={setAccessRequest} setModal={setModal} />
      {/* Bottom to Top Button */}
      <button
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        className={`fixed bottom-6 right-6 w-[35px] h-[35px] text-white bg-[#1a3732] rounded flex justify-center items-center transition-transform ${
          visible
            ? "opacity-100 animate-show-button"
            : "opacity-0 animate-hide-button pointer-events-none"
        }`}
      >
        <DownOutlined className="rotate-180" />
        <span className="scroll-top" />
      </button>
    </div>
  );
};

export default Login;
