/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import kybLogo from "../assets/images/login-footer.png";
import { MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { baseUrl } from "../services/services.profile";
import axios from "axios";
import { message } from "antd";

const Footer = ({ setAccessRequest, setModal }) => {
  const navigate = useNavigate();

  const contactApi = async (email, resetForm) => {
    const res = await axios
      .post(baseUrl + "/api/subscribedEmail", {
        email,
      })
      .then((res) => {
        if (res?.data?.code === 1) {
          message.success(
            "Thank you for your message. We will get back to you as soon as possible."
          );
        } else if (res?.data?.code === -1) {
          message.success("Your email is already exist in our database.");
        }
        resetForm();
      })
      .catch((err) => {
        message.error(res?.data?.msg);
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
        isChecked: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Please enter a valid email")
          .required("Please enter your email"),
      })}
      onSubmit={(values, { resetForm }) => {
        if (values.isChecked) {
          contactApi(values.email, resetForm);
        }
      }}
    >
      {({ handleSubmit, handleChange, values }) => (
        <div className="login-page">
          <footer className="mil-footer-with-bg">
            <div className="border-t border-[#898d9633] container !pt-20">
              <div className="grid grid-cols-12 gap-10">
                <div className="col-span-6 ms:col-span-6 sm:col-span-5 md:col-span-4 lgs:col-span-3">
                  <div
                    onClick={() => {
                      navigate("/");
                    }}
                    className="cursor-pointer mil-footer-logo mb-1.6 sm:mb-[20px] lgs:mb-[24px] !-mt-6"
                  >
                    <img src={kybLogo} alt="Plax" className="h-16" />
                  </div>
                  <p className="lgs:w-[90%] !text-[12px] !font-light">
                    The Fastest Know Your Business (KYB) verification tool ever
                    made for busy AML Experts
                  </p>
                </div>
                <div className="col-span-6 ms:col-span-3 md:col-span-4 lgs:col-span-2 mb-7 lgs:mb-[60px]">
                  <h6 className="mb-5 sm:mb-12 mt-0 !font-semibold !text-[14px]">
                    About
                  </h6>
                  <ul className="mil-footer-list">
                    <li className="mb-1">
                      <a
                        href="https://calendly.com/devloper-flyontech/30min"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Who we serve
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        Trust
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/pricing-page");
                        }}
                      >
                        Pricing
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        FAQs
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        Support
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        Contact form
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href="https://app.ipass.id/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        iPass ID
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-span-6 ms:col-span-3 sm:col-span-4 lgs:col-span-2 mb-7 lgs:mb-[60px]">
                  <h6 className="mb-5 sm:mb-12 mt-0 !font-semibold !text-[14px]">
                    Resources
                  </h6>
                  <ul className="mil-footer-list">
                    <li className="mb-1">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        API
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href="https://api.juste.ai/terms/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href="https://api.juste.ai/privacy_policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href="https://api.juste.ai/cookie_policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Cookie Policy
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href="https://api.juste.ai/disclaimer.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Disclaimer
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setModal(true);
                        }}
                      >
                        Sign in
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setAccessRequest(true);
                        }}
                      >
                        Request access
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-span-6 lgs:col-span-2 mb-7 ms:mb-[60px]">
                  <h6 className="mb-5 sm:mb-12 mt-0 !font-semibold !text-[14px]">
                    Contact
                  </h6>
                  <ul className="mil-footer-list !mb-6">
                    <li className="mb-1">JUSTE AI LTD.</li>
                    <li className="mb-1">71-75 Shelton Street</li>
                    <li className="mb-1">London WC2H 9JQ</li>
                    <li className="mb-1">United Kingdom</li>
                  </ul>
                  <p className="mb-1">
                    <a
                      href="mailto:hello@juste.ai"
                      className="underline !text-[#7f7f7f] !font-light !text-[12px]"
                    >
                      hello@juste.ai
                    </a>
                  </p>
                  <p className="font-outfit font-normal">
                    <a
                      href="tel:+447727407318"
                      className="!text-[#7f7f7f] !font-light !text-[12px]"
                    >
                      +44 77 2740 7318
                    </a>
                  </p>
                </div>

                <div className="col-span-12 ms:col-span-6 lgs:col-span-3 mb-7">
                  <h6 className="mb-5 sm:mb-12 mt-0 !font-semibold !text-[14px]">
                    Stay in touch
                  </h6>
                  <ul className="mil-footer-list">
                    <li className="mb-1">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        LinkedIn
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        Instagram
                      </a>
                    </li>
                    <li className="mb-1">
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        TikTok
                      </a>
                    </li>
                    <li className="mb-1">Newsletter – get the latest news</li>
                  </ul>
                  <form
                    id="subscribeForm"
                    className="mil-subscripe-form-footer mt-4"
                  >
                    <input
                      className="mil-input"
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      value={values.email}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="!text-red-500 font-normal text-[11px] mt-1 ml-2"
                    />

                    <button type="button">
                      <MailOutlined className="!text-[#153831]" />
                    </button>
                    <div className="mil-checkbox-frame mt-5">
                      <div className="mil-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox"
                          name="isChecked"
                          checked={values.isChecked}
                          onChange={(e) => {
                            values.email && handleChange(e);
                            handleSubmit();
                          }}
                        />
                        <label htmlFor="checkbox" />
                      </div>
                      <p className="text-sm mil-soft !text-[8px]">
                        We will only use your email for the purpose of sending
                        you our newsletter.
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mil-footer-bottom">
                <p className="text-[11px] text-center flex flex-col">
                  <div>
                    Copyright © 2024 by JUSTE AI LTD. Company number: 15983150.
                    All rights reserved. JUSTE is a registered trademark.
                  </div>
                  <div>
                    JUSTE AI Ltd. is registered as a Data Processor with the UK
                    Information Commissioner’s Office under number ZB836748.
                  </div>
                </p>
              </div>
            </div>
          </footer>
        </div>
      )}
    </Formik>
  );
};

export default Footer;
