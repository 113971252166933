/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
//services
import { baseUrl, getProfile } from "../services/services.profile";
import { Row, Col, Checkbox, Typography, Input, Button, message } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { Outlet, Link } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Membership() {
  ///6145cc11d91d8537931b3e70_sla.pdf
  const [profile, setProfile] = useState([]);
  const [config, setConfig] = useState({});
  const [checked, setChecked] = useState(false);
  const [eSign, setESign] = useState("");
  const [stripe, setStripe] = useState(null);
  const [details, setDetails] = useState(false);
  const navigate = useNavigate();

  // function HeaderView() {
  //   const location = useLocation();
  //   console.log(location.pathname,"LOCATION");
  //   // return <span>Path : {location.pathname}</span>
  // }
  useEffect(() => {
    // HeaderView()
    if (
      localStorage.getItem("mem_page") &&
      localStorage.getItem("mem_page").includes("ela")
    ) {
      setDetails(true);
    }
    getProfile()
      .then((res) => {
        // moment(res.subscription.current_period_start).format('MMMM Do YYYY, h:mm:ss a')
        //         const date = new Date(res.subscription.current_period_start * 1000);
        // console.log(date,"DATE");
        // console.log(res.sla_sign ,"====" )
        if (res.utype === "PATRON") {
          message.error("Cannot access an area reserved for JUSTE members.");
          navigate("/");
        }
        setProfile(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      const result = await axios.get(baseUrl + "/payment/getconfig");
      setStripe(await loadStripe(result.data.userInfo.publishableKey));
      setConfig(result.data.userInfo);
    };
    fetchConfig();
  }, []);

  const validation = () => {
    if (eSign === "") {
      message.error("Please fill in the signature");
      return false;
    }
    if (checked === false) {
      message.error("Please confirm by checking the box");
      return false;
    }
    if (eSign !== "/s/" + profile.lname) {
      message.error("Please fill in the e-signature correctly");
      return false;
    }
    return true;
  };

  // create checkout
  const createCheckout = async () => {
    if (validation()) {
      const response = await axios
        .post(baseUrl + "/api/create-checkout-session", {
          customerId: config?.customerId,
          priceId: profile?.sla_sign.sub_info.priceId,
          esigned: eSign,
        })
        .then((res) => {
          if (res?.data?.error) {
            message.error(res.data?.error?.message);
          } else {
            message.success("Success");
            console.log(stripe);
            stripe.redirectToCheckout({ sessionId: res.data.id });
            // setTimeout(() => {
            // redirecttoChec
            // window
            //   .open(
            //     "https://checkout.stripe.com/c/pay/" + res.data.id,
            //     "_blank"
            //   )
            //   .focus();
            // }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      {details ? (
        <Row
          gutter={[16, 16]}
          style={{
            flexDirection: "column",
            alignItems: "center",
          }}
          justify="center"
        >
          {/* PDF  */}
          <Row>
            <Col align="center">
              {profile?.sla_sign && (
                <iframe
                  src={baseUrl + "/" + profile?.sla_sign.file_name}
                  width={700}
                  height={700}
                ></iframe>
              )}
            </Col>
          </Row>
          {/* checkbox esign  */}
          <Row>
            <Col>
              <Checkbox
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
              >
                <Typography.Text>
                  I have read and agree to the terms and conditions of the
                  agreement.
                </Typography.Text>
              </Checkbox>
            </Col>
          </Row>
          {/* eSign  */}
          <Row gutter={[16, 16]} align="middle">
            <Col span={10}>
              <Typography.Text>
                eSign /s/last name. Example: /s/{config.lname}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Input
                value={eSign}
                onChange={(e) => {
                  setESign(e.target.value);
                }}
                placeholder="/s/Lastname"
              />
            </Col>
          </Row>
          {/* confirmation text */}
          <Row>
            <Col>
              <Typography.Text>
                A copy of your eSigned Service Level Agreement will be available
                after you confirm
              </Typography.Text>
            </Col>
          </Row>
          {/* button  */}
          <Row>
            <Col>
              <Button type="primary" onClick={createCheckout}>
                Confirm
              </Button>
            </Col>
          </Row>
        </Row>
      ) : // profile.sla_sign ==undefined || !profile?.sla_sign.is_accepted ?  (
      profile?.subscription === undefined &&
        profile?.sla_sign?.is_accepted === false ? (
        <Row className="membership" style={{ flexDirection: "column" }}>
          <p>
            Membership: <strong>Not activated</strong>
          </p>
          <p>
            Start date: <strong>Not activated</strong>
          </p>
          <p>
            End date: <strong>Not activated</strong>
          </p>
          <p>
            Allocated Inspection Reports / Transaction Reports:{" "}
            <strong>Not activated</strong>
          </p>
          <div className="py-4">
            <p>
              For new Memberships: Please visit our{" "}
              {<Link to="/user/pricing">pricing screen </Link>}
              to activate your membership for full access to our services.
            </p>
            <p>
              For existing Memberships:{" "}
              <Link to="/user/pricing">Renew - Upgrade</Link>
            </p>
          </div>
          <p>
            Usage:<strong>Not activated</strong>
          </p>
          <p>
            Remaining: <strong>Not activated</strong>
          </p>
          <br />
          <div className="d-block">
            <p>Service Level Agreement</p>
            <strong>Not activated</strong>{" "}
          </div>
        </Row>
      ) : (
        <Row className="membership" style={{ flexDirection: "column" }}>
          <p>
            Membership: <strong> {profile?.membership?.name}</strong>
          </p>
          <p>
            Start date:{" "}
            <strong>
              {" "}
              {moment(
                profile?.subscription?.current_period_start * 1000
              ).format("MMMM Do YYYY, h:mm:ss a")}
            </strong>
          </p>
          <p>
            End date:{" "}
            <strong>
              {" "}
              {moment(profile?.subscription?.current_period_end * 1000).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </strong>
          </p>
          <p>
            Allocated Inspection Reports / Transaction Reports:{" "}
            <strong>{profile?.membership?.allocatedTransReports}</strong>
          </p>
          <div className="py-4">
            <p>
              For new Memberships: Please visit our{" "}
              {<Link to="/user/pricing">pricing screen </Link>}
              to activate your membership for full access to our services.
            </p>
            <p>
              For existing Memberships:{" "}
              <Link to="/user/pricing">Renew - Upgrade</Link>
            </p>
          </div>
          <p>
            Usage: <strong> {profile?.numberOfTradeConn} </strong>
          </p>
          <p>
            Remaining:{" "}
            <strong>
              {" "}
              {profile?.membership?.allocatedTransReports -
                profile?.numberOfTradeConn}
            </strong>
          </p>
          <br />
          <div className="d-block">
            <p>Service Level Agreement</p>
            <a
              href={baseUrl + "/" + profile?.sla_sign?.file_name}
              className="down-link"
              target="_blank"
            >
              Download
            </a>
          </div>
        </Row>
      )}
    </div>
  );
}

export default Membership;
