/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import kybLogo from "../assets/images/login-header.png";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";

const Header = ({ setSideBar, setModal, setAccessRequest }) => {
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div className="login-page">
        <div className="mil-top-panel bg-white">
          <div className="container flex justify-between items-center">
            <div
              onClick={() => {
                setToggle(false);
                navigate("/");
              }}
              className="mil-logo h-full cursor-pointer"
            >
              <img src={kybLogo} alt="Plax" className="h-9" />
            </div>

            <div className="flex !gap-[80px] sm:gap-6">
              <nav className={`mil-top-menu ${toggle ? "mil-active" : ""}`}>
                <ul>
                  <li className="mil-has-children">
                    <a>
                      Who we serve <DownOutlined className="ml-1" />
                    </a>
                    <ul>
                      <li>
                        <a>Banking & Fintech</a>
                      </li>
                      <li>
                        <a>Real Estate</a>
                      </li>
                      <li>
                        <a>Legal professions</a>
                      </li>
                      <li>
                        <a>Accounting & Tax</a>
                      </li>
                      <li>
                        <a>Art & Luxury</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a>Resources</a>
                  </li>
                  <li>
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        setToggle(false);
                        navigate("/pricing-page");
                      }}
                    >
                      Pricing
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="mil-menu-buttons">
                <div className="cursor-pointer flex justify-center gap-4 sm:gap-6">
                  <div
                    onClick={() => {
                      setModal?.(true);
                      setSideBar?.(false);
                    }}
                    className="cursor-pointer bg-[#1a3732] !text-white h-fit w-fit text-center px-6 py-2.5 rounded-lg font-medium hover:bg-opacity-80 transition-opacity duration-300 text-[13px]"
                  >
                    Log in
                  </div>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setAccessRequest(true);
                      setModal(false);
                    }}
                    className="bg-[#E7F21F] text-[#1a3732] h-fit w-fit text-center px-6 py-2.5 text-[13px] rounded-lg font-medium hover:bg-opacity-80 transition-opacity duration-300"
                  >
                    Request access
                  </div>
                </div>
                <div
                  className={`mil-menu-btn ${toggle ? "mil-active" : ""}`}
                  onClick={() => setToggle(!toggle)}
                >
                  <span />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
