import React, { useState } from "react";
import { Drawer } from "antd";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import "../AddMemberDrawer/addMemberDrawer.css";

const AdminAiMoreDrawer = ({
  manageOptionsDrawer,
  setManageOptionsDrawerClose,
  setIsOpenDocRequestHistory,
  setIsOpenDocHistory,
  handleDocRequest,
  activeButton,
  isAdmin123,
}) => {
  const data = [
    { name: "Communications", click: () => setIsOpenDocRequestHistory(true) },
    {
      name: "Doc History",
      click: () => setIsOpenDocHistory(true),
      condition: activeButton !== "CDD Report",
    },
    {
      name: "Request new CDD",
      click: () => handleDocRequest("cdd"),
      condition: isAdmin123,
    },
    {
      name: "Request new POI",
      click: () => handleDocRequest("poi"),
      condition: isAdmin123,
    },
    {
      name: "Request new UBO",
      click: () => handleDocRequest("ubo"),
      condition: isAdmin123,
    },
  ];
  return (
    <>
      <Drawer
        placement="right"
        title={
          <div className="flex justify-between items-center pt-2 px-5">
            <p className="text-xl font-bold">More Options</p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                setManageOptionsDrawerClose();
              }}
            />
          </div>
        }
        className="addMember"
        closable={false}
        onClose={() => {
          setManageOptionsDrawerClose();
        }}
        open={manageOptionsDrawer}
      >
        <div className="h-full w-full py-2 px-2 flex flex-col gap-5">
          {data.map((value, index) =>
            value.condition === undefined || value.condition ? (
              <div
                key={index}
                onClick={value?.click}
                className="bg-[#fff] w-full cursor-pointer rounded-xl shadow-md sm:p-5 p-4 flex justify-between items-center gap-4"
              >
                <div className="flex items-center gap-2">
                  <div className="text-[14px] font-extrabold">
                    {value?.name}
                  </div>
                </div>
                <div className="flex align-center gap-3">
                  <div>
                    <RightOutlined />
                  </div>
                </div>
              </div>
            ) : null
          )}
        </div>
      </Drawer>
    </>
  );
};

export default AdminAiMoreDrawer;
