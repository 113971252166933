import React from "react";
import welcome from "../assets/images/home-1/welcome.png";
import { useNavigate } from "react-router";

const RequestSubmitted = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="login-page !bg-white">
        <div className="container !mt-[80px]">
          <div className=" lgb:grid grid-cols-12 gap-6 lgb:gap-12 justify-between pt-[60px] pb-[100px]">
            <div className="col-span-6 mb-5 sm:mb-[60px] lgb:mb-0 px-4 sm:px-6 md:px-0">
              <p className="mb-4 text-[15px] !font-normal text-center">
                THANKS FOR CONTACTING US
              </p>
              <h3 className="mb-7 lgb:mb-9 mt-0 !font-bold !text-[48px] text-center">
                We've successfully received your access request!
              </h3>
              <div className=" !font-medium text-center !text-black">
                Thank you for scheduling a demo-this is a great opportunity for
                us to understand your needs, timelines, and how KYB Pro can best
                support your goals.
              </div>
              <div className=" !font-medium text-center !text-black mt-[16px]">
                You'll receive a confirmation email with the details shortly. In
                the meantime, feel free to explore{" "}
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className="!text-[#111111] !underline !hover:text-[#111111]"
                  href=""
                >
                  KYB Pro
                </button>
                &nbsp;or reach out if you have any questions.
              </div>
              <div className=" !font-medium text-center !text-black mt-[16px]">
                Looking forward to our conversation!
              </div>
              <div className=" !font-medium text-center !text-black mt-[16px]">
                — The KYB Pro Team
              </div>
            </div>
            <div className="col-span-6">
              <div className="h-full flex justify-center">
                <img
                  src={welcome}
                  alt="Welcome"
                  className="h-full rounded-mb-10 lgb:rounded-none w-4/5 sm:w-3/4 md:w-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestSubmitted;
